<div class="content">


  <div class="logo">
    <img src="../../../../../assets/images/logo.png" width="125" height="50">
  </div>


  <nav>

    <ul>

      <a *ngFor="let tab of tabs"  (click)="navigate(tab.route)">
        <li>
          {{tab.name}}
        </li>
      </a>

    </ul>

  </nav>

  <div class="spacer"></div>

  <div class="logout" (click)="logout()">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-power" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z"/>
      <path fill-rule="evenodd" d="M7.5 8V1h1v7h-1z"/>
    </svg>
  </div>

</div>
