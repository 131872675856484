import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { PrePay } from '@vizodev/syntezza-sales-types/lib';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DatabaseService } from 'src/app/core/database/database.service';
import { LabsService } from 'src/app/services/labs/labs.service';
import { PrepaysService } from 'src/app/services/prepays/prepays.service';

import { AddPrepayDialogComponent } from '../../../components/dialogs/add-prepay-dialog/add-prepay-dialog.component';

@Component({
  selector: 'app-lab-prepays',
  templateUrl: './lab-prepays.component.html',
  styleUrls: ['./lab-prepays.component.scss'],
})
export class LabPrepaysComponent implements OnInit {
  subscriptions = new Subscription();

  columnsToDisplay = ['date', 'amount', 'extra', 'total', 'sap', 'actions'];

  dataSource = new MatTableDataSource<PrePay>([]);

  constructor(
    private dialog: MatDialog,
    public prepaysService: PrepaysService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.prepaysService.prepays$.subscribe(
        (prepays) => (this.dataSource = new MatTableDataSource<PrePay>(prepays))
      )
    );
  }

  editPrepay(prepay: PrePay) {
    //console.log('Open Prepay', this.prepaysService.initialBalance);

    const dialogRef = this.dialog.open(AddPrepayDialogComponent, {
      width: '440px',
      data: {
        ...prepay.toMap(),
        minDate: this.prepaysService.initialBalance?.date,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result === 'DELETE') {
          return this.deletePrepay(prepay);
        }
        this.prepaysService.updatePrepay(result);
      }
    });
  }

  deletePrepay(prepay: PrePay) {
    if (!confirm('Are you sure you want to delete this prepay?')) return;
    return this.prepaysService.deletePrepay(prepay);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
