import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseService } from 'src/app/core/database/database.service';

@Injectable({
  providedIn: 'root',
})
export class PisService {
  pisSubject = new BehaviorSubject<string[]>([]);

  constructor(private database: DatabaseService) {
    this.getPis();
  }

  public get pis$(): Observable<string[]> {
    return this.pisSubject.asObservable();
  }

  async getPis() {
    const raw = (await this.database.getFilters()) as any;
    this.pisSubject.next(
      !raw?.pis
        ? []
        : Object.keys(raw.pis)
    );
  }
}
