import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private _call(
    method: 'POST' | 'GET',
    path: string,
    headers?: any,
    body?: any,
    responseType?: any
  ) {
    let req;
    switch (method) {
      case 'GET':
        req = this.http.get(environment.endpoint + path, {
          responseType,
          headers,
        });
        break;
      case 'POST':
        req = this.http.post(environment.endpoint + path, body, { headers });
        break;
    }

    return req;
  }

  async createReport(labId: string, date: Date, piName: string) {
    return new Promise((resolve, rej) => {
      this._call(
        'GET',
        `/lab/${labId}/report/${date ? date.getTime() : ''}`,
        undefined,
        undefined,
        'arraybuffer'
      )
        .pipe(
          take(1),
          catchError((e) => {
            console.error(`Error _call/lab/${labId}/report`, e.error);
            rej(e.error);
            return of(null);
          })
        )
        .subscribe((res) => {
          //console.log('Res', res);
          resolve(res);
        });
    });
  }

  async exportAccounts(
    prepay: boolean | null,
    organizationId: string | null,
    minOrderDate: string | null,
    maxOrderDate: string | null,
    maxBalance: string | null
  ) {
    let path = `/export/accounts?`;
    if (prepay !== null) path += `prepay=${prepay}&`;
    if (organizationId !== null) path += `organizationId=${organizationId}&`;
    if (minOrderDate !== null) path += `minOrderDate=${minOrderDate}&`;
    if (maxOrderDate !== null) path += `maxOrderDate=${maxOrderDate}&`;
    if (maxBalance !== null) path += `maxBalance=${maxBalance}&`;
    return new Promise((resolve, rej) => {
      this._call('GET', path, undefined, undefined, 'arraybuffer')
        .pipe(
          take(1),
          catchError((e) => {
            console.error(`Error /export/accounts`, e.error);
            rej(e.error);
            return of(null);
          })
        )
        .subscribe((res) => {
          //console.log('Res', res);
          resolve(res);
        });
    });
  }

  async exportOrders(
    origin: string | null,
    minOrderDate: string | null,
    maxOrderDate: string | null
  ) {
    let path = `/export/orders?`;
    if (origin !== null) path += `origin=${origin}&`;
    if (minOrderDate !== null) path += `minOrderDate=${minOrderDate}&`;
    if (maxOrderDate !== null) path += `maxOrderDate=${maxOrderDate}&`;
    return new Promise((resolve, rej) => {
      this._call('GET', path, undefined, undefined, 'arraybuffer')
        .pipe(
          take(1),
          catchError((e) => {
            console.error(`Error /export/orders`, e.error);
            rej(e.error);
            return of(null);
          })
        )
        .subscribe((res) => {
          //console.log('Res', res);
          resolve(res);
        });
    });
  }

  async exportContacts() {
    return new Promise((resolve, rej) => {
      this._call('GET', `/export/contacts`, undefined, undefined, 'arraybuffer')
        .pipe(
          take(1),
          catchError((e) => {
            console.error(`Error /export/orders`, e.error);
            rej(e.error);
            return of(null);
          })
        )
        .subscribe((res) => {
          //console.log('Res', res);
          resolve(res);
        });
    });
  }
}
