import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index.component';
import { ExportComponent } from './pages/export/export.component';
import { HomeComponent } from './pages/home/home.component';
import { LabDetailsComponent } from './pages/lab-details-pages/lab-details/lab-details.component';
import { LabOrdersComponent } from './pages/lab-details-pages/lab-orders/lab-orders.component';
import { LabPrepaysComponent } from './pages/lab-details-pages/lab-prepays/lab-prepays.component';
import { LabComponent } from './pages/lab-details-pages/lab/lab.component';
import { SearchComponent } from './pages/search/search.component';
import { UploadComponent } from './pages/upload/upload.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'search',
        component: SearchComponent,
      },
      {
        path: 'export',
        component: ExportComponent,
      },
      {
        path: 'upload',
        component: UploadComponent,
      },
      {
        path: ':labId',
        component: LabComponent,
        children: [
          {
            path: '',
            component: LabDetailsComponent,
            data: { index: 0 },
          },
          {
            path: 'orders',
            component: LabOrdersComponent,
            data: { index: 1 },
            children: [
              {
                path: ':orderId',
                component: LabOrdersComponent,
              }
            ]
          },
          {
            path: 'prepays',
            component: LabPrepaysComponent,
            data: { index: 2 },
          },
          {
            path: 'details',
            redirectTo: ''
          },
        ]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
