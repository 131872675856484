<div class="container">
  <div class="pdf-container">

    <pdf-viewer [src]="pdfUrl" [rotation]="rotation" [render-text]="true" [zoom]="zoomValue" [autoresize]="true" zoom-scale="page-fit">
    </pdf-viewer>

  </div>

  <div class="button-bar">
    <button type="button" class="btn btn-secondary mr" (click)="download()">Download</button>
    <div class="spacer">
    </div>
    <button type="button" class="btn btn-info mr" (click)="rotate()">
      <i class="fas fa-undo"></i>
    </button>
    <button type="button" class="btn btn-info mr" (click)="zoom(-.1)">
      <i class="fas fa-search-minus"></i>
    </button>
    <button type="button" class="btn btn-info" (click)="zoom(.1)">
      <i class="fas fa-search-plus"></i>
    </button>
  </div>
</div>
