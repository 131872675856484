<div class="content">

  <main>
    <app-header> </app-header>
    <section>
      <router-outlet></router-outlet>
    </section>
  </main>

</div>
