<div class="row ">
  <div class="col-12 d-flex flex-row-reverse my-2">
    <mat-button-toggle-group name="view" [(ngModel)]="prepaysService.view" #group="matButtonToggleGroup">
      <mat-button-toggle [value]="0" (change)="prepaysService.changeView(0)">All</mat-button-toggle>
      <mat-button-toggle [value]="1" (change)="prepaysService.changeView(1)" [disabled]="true">12 months</mat-button-toggle>
      <mat-button-toggle [value]="2" (change)="prepaysService.changeView(2)" [disabled]="true">Last month</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let data"> {{data.date | date: 'dd/MMM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef> Amount </th>
    <td mat-cell *matCellDef="let data">NIS {{ data.amount | number }} </td>
  </ng-container>

  <ng-container matColumnDef="extra">
    <th mat-header-cell *matHeaderCellDef> Extra </th>
    <td mat-cell *matCellDef="let data"> NIS {{ data.extraAmount | number }}</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef> Total </th>
    <td mat-cell *matCellDef="let data"> NIS {{ data.totalAmount | number }}</td>
  </ng-container>

  <ng-container matColumnDef="sap">
    <th mat-header-cell *matHeaderCellDef> SAP Invoice </th>
    <td mat-cell *matCellDef="let data"> {{data.sapInvoice}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let data"> <a [routerLink]="" (click)="editPrepay(data)">Edit</a> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>

<div class="mt-4 d-flex justify-content-center">
  <button mat-raised-button [disabled]="prepaysService.disableLoadMore" (click)="prepaysService.loadMore()">Load
    More</button>
</div>
