import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { RouterService } from 'src/app/core/router/router.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService implements OnDestroy {
  private authStateSubject = new BehaviorSubject<boolean>(false);

  subscription = new Subscription();
  constructor(private auth: AuthService, private router: RouterService) {
    this.subscription.add(
      this.auth.user.subscribe((user) => {
        this.authStateSubject.next(!!user);
      })
    );

    this.subscription.add(
      this.auth.authState.subscribe((user) => {
        this.authStateSubject.next(!!user);
      })
    );

    // this.subscription.add(
    //   this.authState.subscribe(auth => !auth ? this.router.goToLogin() : null)
    // );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public get authState(): Observable<boolean> {
    return this.authStateSubject.asObservable();
  }

  userLoginStream() {
    this.auth.authState.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.goToLogin();
      }
    });
  }

  async login(email: string, password: string) {
    try {
      await this.auth.login(email, password);
      return true;
    } catch (error) {
      throw error.message;
    }
  }

  async forgotPassword(email: string) {
    try {
      await this.auth.forgotPassword(email);
      return true;
    } catch (error) {
      throw error.message;
    }
  }

  async logout() {
    try {
      await this.auth.logout();
      return true;
    } catch (error) {
      throw error.message;
    }
  }
}
