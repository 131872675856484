import { Injectable, OnDestroy } from '@angular/core';
import { Balance, Laboratory, Order } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, pipe, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';
import { LabsService } from '../labs/labs.service';

enum Views {
  ALL,
  LAST_YEAR,
  LAST_MONTH,
  LAST_PREPAY,
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService implements OnDestroy {
  private ordersSubject = new BehaviorSubject<Order[]>([]);

  lab: Laboratory;
  initialBalance: Balance;
  limit = 50;
  lastSnap: any;
  disableLoadMore = false;

  view: Views = Views.ALL;

  sub = new Subscription();
  constructor(
    private database: DatabaseService,
    private alertService: AlertService,
    public labService: LabsService
  ) {
    this.sub.add(
      labService.currentLab$.subscribe((lab) => {
        this.clear();
        if (lab) this.loadOrders(lab);
      })
    );
    this.sub.add(
      labService.currentInitialBalance$.subscribe((b) => {
        this.initialBalance = b;
      })
    );
  }

  public get orders$() {
    return this.ordersSubject.asObservable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async getOrderById(orderId: string) {
    let lab;
    if (!this.lab)
      lab = await this.labService.currentLab$.pipe(take(1)).toPromise();
    else lab = this.lab;
    return this.database.getOrderById(lab.laboratoryId, orderId);
  }

  changeView(view: Views) {
    this.view = view;
    this.lastSnap = null;
    this.loadOrders(this.lab, true);
  }

  viewToQuery(view: Views) {
    switch (view) {
      case Views.ALL:
        return null;
      case Views.LAST_MONTH:
        const d = new Date();
        const month = d.getMonth();
        if (month === 1) {
          d.setFullYear(d.getFullYear() - 1, 12, 1);
        } else d.setMonth(month - 1, 1);
        d.setHours(0, 0, 0, 0);
        return d;
      case Views.LAST_YEAR:
        const d1 = new Date();
        d1.setFullYear(d1.getFullYear() - 1, 0, 1);
        d1.setHours(0, 0, 0, 0);
        return d1;
      case Views.LAST_PREPAY:
        return this.lab.lastPrepayInfo?.date;
    }
  }

  updateInitialBalance(value: number) {
    return this.labService.updateInitialBalance(value);
  }

  loadOrders(lab: Laboratory, reload?: boolean) {
    if (this.lab?.laboratoryId === lab?.laboratoryId && !reload) return;
    this.lab = lab;
    this.disableLoadMore = false;
    this.sub.add(
      this.database
        .getOrdersFromLaboratory(
          lab.laboratoryId,
          this.limit,
          this.viewToQuery(this.view)
        )
        .subscribe((orders) => {
          this.lastSnap = orders.length
            ? orders[orders.length - 1].payload.doc
            : null;
          this.ordersSubject.next(
            orders.map((o) => Order.fromFirestore(o.payload.doc))
          );
        })
    );
  }

  loadMore() {
    this.sub.add(
      this.database
        .getOrdersFromLaboratory(
          this.lab.laboratoryId,
          this.limit,
          this.viewToQuery(this.view),
          this.lastSnap
        )
        .pipe(take(1))
        .subscribe((orders) => {
          if (orders.length) {
            this.lastSnap = orders[orders.length - 1].payload.doc;
            if (orders.length % this.limit === 0) this.disableLoadMore = false;
            else this.disableLoadMore = true;
            this.ordersSubject.next([
              ...this.ordersSubject.value,
              ...orders.map((o) => Order.fromFirestore(o.payload.doc)),
            ]);
          } else {
            this.disableLoadMore = true;
          }
        })
    );
  }

  async updateOrder(order: Order) {
    try {
      await this.database.updatedOrder(Order.fromMap(order));
      this.alertService.success('');
    } catch (error) {
      console.error(error);
      this.alertService.error(JSON.stringify(error));
    }
  }

  async deleteOrder(order: Order) {
    try {
      await this.database.deleteOrder(order);
      this.alertService.success('');
    } catch (error) {
      console.error(error);
      this.alertService.error(JSON.stringify(error));
    }
  }

  getBalance(date: Date) {
    return this.database.getBalanceForDate(this.lab.laboratoryId, date);
  }

  clear() {
    this.lab = null;
    this.lastSnap = null;
    this.ordersSubject.next([]);
  }
}
