
<div class="content">

  <div class="name">
    <p>{{ file.name }}</p>
  </div>

  <div class="percentage">
    <div *ngIf="percentage | async as pct">
      <progress [value]="pct" max="100"></progress>
      {{ pct | number }}%
    </div>
  </div>

</div>
