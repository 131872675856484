<div class="filters-buttons-container my-4 row">

  <div class="filters col-md-10 row">

    <div class="input col-md-3">
      <span>Search (BP code)</span>
      <div class="form-group has-search labs-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="number" class="form-control" [formControl]="queryControl">
      </div>
    </div>

    <div class="select col-md-2" *ngIf="filters$ | async as filters">
      <span>PrePay</span>
      <select class="browser-default custom-select has-prepay" [formControl]="prepayControl">
        <option [value]="null"> All </option>
        <option selected [value]="item" *ngFor="let item of [true, false]">{{ item }}</option>
      </select>

    </div>

    <div class="select col-md-3" *ngIf="filters$ | async as filters">
      <span>PI</span>
      <mat-select class="form-control" [formControl]="piControl" placeholder="PI" #singleSelect>
        <mat-option>
          <ngx-mat-select-search noEntriesFoundLabel="Nothing found" placeholderLabel="Type something"
            [formControl]="piFilter"></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let pi of filteredPis | async" [value]="pi">
          {{pi}}
        </mat-option>
      </mat-select>
    </div>

    <div class="select col-md-3" *ngIf="filters$ | async as filters">
      <span>Organization</span>
      <mat-select class="form-control" [formControl]="organizationControl" placeholder="Organization" #singleSelect>
        <mat-option>
          <ngx-mat-select-search noEntriesFoundLabel="Nothing found" placeholderLabel="Type something"
            [formControl]="orgFilter"></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">All</mat-option>
        <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
          {{org.name}}
        </mat-option>
      </mat-select>
    </div>

    <div class="col-md-1 d-flex align-items-center justify-content-center">
      <button mat-icon-button (click)="labsService.refresh()">
          <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="buttons col-md-2 d-flex justify-content-end align-items-center">
    <button type="button" mat-raised-button (click)="createLab()" class="btn btn-secondary">Add Laboratory</button>
  </div>

</div>

<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="bpCode">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('bpCode')"
      [ngStyle]="{'font-weight': orderByValue === 'bpCode' ? 'bold' : 'normal'}"> BP Number
      <mat-icon *ngIf="orderByValue === 'bpCode' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'bpCode' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> {{data.bpCode}} </td>
  </ng-container>

  <ng-container matColumnDef="organizationName">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('organization.name')"
      [ngStyle]="{'font-weight': orderByValue === 'organization.name' ? 'bold' : 'normal'}"> Organization
      <mat-icon *ngIf="orderByValue === 'organization.name' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'organization.name' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> {{data.organization.name}} </td>
  </ng-container>

  <ng-container matColumnDef="piName">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('pi.name')"
      [ngStyle]="{'font-weight': orderByValue === 'pi.name' ? 'bold' : 'normal'}"> PI
      <mat-icon *ngIf="orderByValue === 'pi.name' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'pi.name' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> {{data.pi.name}} </td>
  </ng-container>

  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('balance')"
      [ngStyle]="{'font-weight': orderByValue === 'balance' ? 'bold' : 'normal'}"> Balance
      <mat-icon *ngIf="orderByValue === 'balance' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'balance' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> NIS {{data.balance | number}} </td>
  </ng-container>

  <ng-container matColumnDef="lastPrepayDate">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('lastPrepayInfo.date')"
      [ngStyle]="{'font-weight': orderByValue === 'lastPrepayInfo.date' ? 'bold' : 'normal'}"> Last Prepay Date
      <mat-icon *ngIf="orderByValue === 'lastPrepayInfo.date' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'lastPrepayInfo.date' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> {{!data.lastPrepayInfo ? '' : data.lastPrepayInfo.date | date: 'dd/MMM/yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="prepayAmount">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('lastPrepayInfo.totalAmount')"
      [ngStyle]="{'font-weight': orderByValue === 'lastPrepayInfo.totalAmount' ? 'bold' : 'normal'}"> Prepay amount
      <mat-icon *ngIf="orderByValue === 'lastPrepayInfo.totalAmount' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'lastPrepayInfo.totalAmount' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> NIS {{!data.lastPrepayInfo ? 0 : data.lastPrepayInfo.totalAmount | number}}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastOrderDate">
    <th mat-header-cell *matHeaderCellDef (click)="orderBy('lastOrderInfo.date')"
      [ngStyle]="{'font-weight': orderByValue === 'lastOrderInfo.date' ? 'bold' : 'normal'}"> Last Order Date
      <mat-icon *ngIf="orderByValue === 'lastOrderInfo.date' && direction === 'desc'">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="orderByValue === 'lastOrderInfo.date' && direction === 'asc'">arrow_drop_up</mat-icon>
    </th>
    <td mat-cell *matCellDef="let data"> {{!data.lastOrderInfo ? '' : data.lastOrderInfo .date | date: 'dd/MMM/yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let data"> <a (click)="openLab(data)">Open</a></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>

<div class="mt-4 d-flex justify-content-center">
  <button mat-raised-button [disabled]="disableLoadMore" (click)="loadMore()">Load More</button>
</div>
