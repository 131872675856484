<h1 mat-dialog-title>{{ this.data ? 'Edit' : 'Add'}} PrePay</h1>
<div mat-dialog-content [formGroup]="prepayForm">

  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>SAP Invoice</mat-label>
        <input matInput formControlName="sapInvoice">
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field>
        <mat-label>PO Number</mat-label>
        <input matInput formControlName="poNumber">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" type="number">
      </mat-form-field>
    </div>
    <div class="col-6">

      <mat-form-field>
        <mat-label>Extra Amount</mat-label>
        <input matInput formControlName="extraAmount" type="number">
      </mat-form-field>
    </div>
  </div>


  <div class="row">

    <div class="col-6">
      <mat-form-field style="width: 100%;">
        <mat-label>Date</mat-label>
        <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-form-field style="width: 100%;">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName='notes'> </textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <p class="text-end">Total: NIS {{ totalAmount | number }}</p>

    </div>
  </div>

</div>
<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="onDiscard()">Cancel</button>
  <button mat-flat-button *ngIf="data && data.date" color="warn" (click)="this.dialogRef.close('DELETE')"
    [disabled]="data.date.getTime() < minDate.getTime()">Delete</button>
  <button mat-flat-button color="primary" (click)="save()"
    [disabled]="(data && data.date && data.date.getTime() < minDate.getTime()) ||!prepayForm.valid || stringToDate(prepayForm.value.date).getTime() < minDate.getTime()"
    cdkFocusInitial>Save</button>
</div>
