import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from '@vizodev/syntezza-sales-types/lib';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { RouterService } from 'src/app/core/router/router.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { SearchService } from 'src/app/services/search/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  subscriptions = new Subscription();

  columnsToDisplay = [
    'contactPerson',
    'date',
    'origin',
    'orderNum',
    'webNum',
    'amount',
    'actions',
  ];

  dataSource = new MatTableDataSource<Order>([]);

  isSearching = false;

  queryControl = new FormControl();

  constructor(public searchService: SearchService, private router: RouterService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.searchService.orders$.subscribe((orders) => {
        this.dataSource = new MatTableDataSource<Order>(orders);
        this.isSearching = false;
      })
    );

    this.subscriptions.add(
      this.queryControl.valueChanges
        .pipe(
          tap(() => this.searchService.clear()),
          debounceTime(500),
          distinctUntilChanged(),
          filter((q) => q?.length >= 3)
        )
        .subscribe((q: any) => {
          this.isSearching = true;
          this.searchService.changeQueryFilter(q.trim());
        })
    );
  }


  openOrder(order: Order) {
    this.router.openOrder(order);
  }

}
