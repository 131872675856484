import { Injectable, OnDestroy } from '@angular/core';

import {
  Balance,
  ContactPerson,
  Laboratory,
  Order,
  OrderOrigin,
  Organization,
  PI,
  PrePay,
} from '@vizodev/syntezza-sales-types/lib';

import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { ApiService } from 'src/app/core/api/api.service';
import { DatabaseService } from 'src/app/core/database/database.service';
import { RouterService } from 'src/app/core/router/router.service';

import { AlertService } from '../alert/alert.service';
import { ContactsService } from '../contacts/contacts.service';
import { FiltersService } from '../filters/filters.service';
import { OrdersService } from '../orders/orders.service';
import { PrepaysService } from '../prepays/prepays.service';

@Injectable({
  providedIn: 'root',
})
export class LabsService implements OnDestroy {
  subscriptions = new Subscription();

  private labsSubject = new BehaviorSubject<Laboratory[]>([]);
  private currentLabSubject = new BehaviorSubject<Laboratory>(null);
  private currentInitialBalanceSubject = new BehaviorSubject<Balance>(null);

  limit = 50;
  lastSnap: any;
  filters: any;
  orderBy = 'bpCode';
  direction: 'asc' | 'desc' = 'asc';
  disableLoadMore = false;

  exporting = false;

  constructor(
    private _databaseService: DatabaseService,
    private api: ApiService,
    private router: RouterService,
    private alertService: AlertService,
    private filterService: FiltersService,
    private prepaysService: PrepaysService,
    private contactsService: ContactsService
  ) {
    this.subscriptions.add(
      this.filterService.reload$.subscribe((data) => {
        this.lastSnap = null;
        this.getLabs(data?.filters, data?.orderBy, data.direction);
      })
    );

    this.subscriptions.add(
      this.currentLab$.subscribe((lab) => {
        if (lab) {
          this.getInitialBalance(lab.laboratoryId);
          this.prepaysService.loadPrepays(lab);
          this.contactsService.loadContacts(lab.laboratoryId);
          this.router.goToLab(lab);
        }
      })
    );
  }

  public get labs$() {
    return this.labsSubject.asObservable();
  }

  public get labs() {
    return this.labsSubject.value;
  }

  public get currentLab$() {
    return this.currentLabSubject.asObservable();
  }

  public get currentInitialBalance$() {
    return this.currentInitialBalanceSubject.asObservable();
  }

  public get currentInitialBalance() {
    return this.currentInitialBalanceSubject.value;
  }

  public get currentLab() {
    return this.currentLabSubject.value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refresh() {
    this.lastSnap = null;
    this.getLabs(this.filters, this.orderBy, this.direction);
  }

  getLabs(filters: any, orderBy?: string, direction: 'asc' | 'desc' = 'asc') {
    this.filters = filters;
    this.orderBy = orderBy;
    this.direction = direction;
    this.subscriptions.add(
      this._databaseService
        .getLaboratories(
          this.limit,
          filters?.query,
          filters?.organization,
          filters?.pi,
          filters?.prepay,
          orderBy,
          this.direction,
          this.lastSnap
        )
        .pipe(
          catchError((e) => {
            console.error(e);
            alert(
              'An error occurred while getting the results:\n' +
                e +
                '\n\n Please copy the error above and send it to dudi@vizo.dev'
            );
            return of(null);
          })
        )
        .subscribe((labs) => {
          if (labs.length) this.lastSnap = labs[labs.length - 1]?.payload.doc;
          this.labsSubject.next(
            labs.map((o) => Laboratory.fromFirestore(o.payload.doc))
          );
          this.disableLoadMoreButton();
        })
    );
  }

  loadMore() {
    this.subscriptions.add(
      this._databaseService
        .getLaboratories(
          this.limit,
          this.filters?.query,
          this.filters?.organization,
          this.filters?.pi,
          this.filters?.prepay,
          this.orderBy,
          this.direction,
          this.lastSnap
        )
        .pipe(
          catchError((e) => {
            console.error(e);
            alert(
              'An error occurred while getting the results:\n' +
                e +
                '\n\n Please copy the error above and send it to dudi@vizo.dev'
            );
            return of(null);
          })
        )
        .subscribe((labs) => {
          if (labs.length) {
            this.lastSnap = labs[labs.length - 1]?.payload.doc;
            const results = [
              ...this.labsSubject.value,
              ...labs.map((o) => Laboratory.fromFirestore(o.payload.doc)),
            ].sort((a, b) => {
              return a[this.orderBy] - b[this.orderBy];
            });
            //console.log('Results', results);
            this.labsSubject.next(results);
          }
          this.disableLoadMoreButton();
        })
    );
  }

  disableLoadMoreButton() {
    this.disableLoadMore =
      this.labsSubject.value.length === 0 ||
      this.labsSubject.value.length % this.limit !== 0;
  }

  open(lab: Laboratory) {
    this.currentLabSubject.next(lab);
  }

  async getLab(labId: string) {
    if (labId === this.currentLab?.laboratoryId) {
      return;
    }

    let lab;
    try {
      lab = await this._databaseService.getLabById(labId);
      if (lab) this.currentLabSubject.next(lab);
    } catch (error) {
      this.alertService.error('Error getting lab: ' + error);
      this.currentLabSubject.next(null);
    }

    if (!lab) {
      this.router.goToHome();
    }
  }

  async getInitialBalance(labId: string) {
    const initialBalance = await this._databaseService.getInitialBalance(labId);
    this.prepaysService.initialBalance = initialBalance;
    //console.log('Set initial balance');

    this.currentInitialBalanceSubject.next(initialBalance);
  }

  hygienizePiName(name: string) {
    return name.replace(/\.\$\[\]\#\//g, ' ');
  }

  async createLab(
    bpCode: number,
    contactName: string,
    discount: boolean,
    email: string,
    phone: string,
    extraCredit: boolean,
    notes: string,
    org: Organization,
    prepay: boolean,
    piName: string,
    initialBalance: number,
    contactId: string,
    initialBalanceDate: Date
  ) {
    const organization =
      org instanceof Organization ? org : Organization.fromMap(org);

    const pi = new PI(
      this._databaseService.createId,
      this.hygienizePiName(piName)
    );

    const lab = new Laboratory(
      this._databaseService.createId,
      bpCode,
      pi,
      notes,
      0,
      organization,
      email,
      phone,
      !!contactName ? contactName : pi.name,
      null,
      null,
      discount,
      prepay,
      extraCredit
    );

    const p = [];

    if (contactId) {
      const contactPerson = new ContactPerson(
        this._databaseService.createId,
        contactId,
        lab.toInfo(),
        contactName
      );
      p.push(this._databaseService.saveContactPerson(contactPerson));
    }

    const balance = new Balance(
      'initial',
      lab.toInfo(),
      initialBalanceDate,
      0,
      initialBalance
    );

    p.push(this._databaseService.saveLaboratory(lab));
    p.push(this._databaseService.saveBalance(balance));

    try {
      await Promise.all(p);
      this.alertService.success('Success!');
    } catch (error) {
      this.alertService.error('Error creating Lab: ' + JSON.stringify(error));
    }
  }

  async updateLab(lab: Laboratory) {
    try {
      lab.pi.name = this.hygienizePiName(lab.pi.name);
      await this._databaseService.updateLaboratory(lab);
      this.alertService.success('');
      this.currentLabSubject.next(lab);
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  async deleteLab(lab: Laboratory) {
    try {
      await this._databaseService.deleteLaboratory(lab.laboratoryId);
      this.alertService.success('');
      this.router.goToHome();
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  async updateInitialBalance(balance: Balance | number) {
    if (balance instanceof Balance) {
      try {
        balance.balanceId = 'initial';
        await this._databaseService.saveBalance(balance);
        this.alertService.success('');
        this.currentInitialBalanceSubject.next(balance);
      } catch (error) {
        this.alertService.error(JSON.stringify(error));
      }
    } else if (typeof balance === 'number') {
      try {
        await this._databaseService.updateBalanceCurrentValue(
          this.currentLabSubject.value.laboratoryId,
          'initial',
          balance
        );
        this.alertService.success('');
      } catch (error) {
        this.alertService.error(JSON.stringify(error));
      }
    }
  }

  async addOrder(
    lab: Laboratory,
    orderNumber: string,
    webNumber: string,
    contactPerson: ContactPerson,
    date: Date,
    amount: number,
    origin: OrderOrigin
  ) {
    const order = new Order(
      null,
      date,
      orderNumber,
      webNumber,
      ContactPerson.fromMap(contactPerson),
      origin,
      amount,
      lab.balance
    );

    try {
      await this._databaseService.createOrder(lab.laboratoryId, order);
      this.alertService.success('');
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  async addPrepay(
    lab: Laboratory,
    sapInvoice: string,
    poNumber: string,
    amount: number,
    extraAmount: number,
    date: Date
  ) {
    const prepay = new PrePay(
      null,
      lab.toInfo(),
      date,
      sapInvoice,
      poNumber,
      extraAmount,
      amount,
      extraAmount + amount,
      lab.balance
    );

    try {
      await this._databaseService.createPrePay(prepay);
      this.alertService.success('');
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  addContactPerson(lab: Laboratory, contactId: string, name: string) {
    return this.contactsService.addContactPerson(lab, contactId, name);
  }

  updateContactPerson(contact: ContactPerson) {
    return this.contactsService.updateContactPerson(contact);
  }

  deleteContactPerson(contactPerson: ContactPerson) {
    return this.contactsService.deleteContactPerson(contactPerson);
  }

  async exportReport(labId: string, date: Date, piName: string) {
    this.exporting = true;
    try {
      const res: any = await this.api.createReport(labId, date, piName);

      const blob = new Blob([res], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      var downloadLink = document.createElement('a');

      downloadLink.href = url;
      downloadLink.download = `labReport_${piName}_${new Date().toDateString()}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      try {
        var dec = new TextDecoder();
        this.alertService.error(dec.decode(error));
      } catch (error) {
        alert(error);
      }
    }
    this.exporting = false;
  }

  async recalculateBalance() {
    try {

      const balances = await this._databaseService.getBalancesAfterDate(this.currentLab.laboratoryId, this.currentInitialBalance.date);
      if (!balances.length) {
        return await this.setLabBalance(this.currentInitialBalance.currentBalance);
      }
      await this._databaseService.deleteBalance(balances[0]);
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  setLabBalance(balance: number) {
    return this._databaseService.updateLaboratoryBalance(this.currentLab.laboratoryId, balance);
  }
}
