<div class="filters-buttons-container my-4 row">

  <div class="filters col-md-12 row">

    <div class="input col-md-12">
      <span>Search Order number (exact matches)</span>
      <div class="form-group has-search labs-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" class="form-control" [formControl]="queryControl">
      </div>
    </div>

  </div>
</div>

<div *ngIf="dataSource.data.length === 0 && queryControl.value?.length < 3">
  <p class="text-center">Type something in the search field</p>
</div>

<div *ngIf="dataSource.data.length === 0 && queryControl.value?.length >= 3 && !isSearching">
  <p class="text-center">Oops... I found nothing of the sort...</p>
</div>

<div *ngIf="isSearching">
  <p class="text-center">Hm... Let me see...</p>
</div>

<table mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0">

  <ng-container matColumnDef="contactPerson">
    <th mat-header-cell *matHeaderCellDef> End-user name </th>
    <td mat-cell *matCellDef="let data"> {{data.contactPerson.name}} </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let data"> {{data.date | date: 'dd/MMM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef> Supplier </th>
    <td mat-cell *matCellDef="let data"> {{data.origin}} </td>
  </ng-container>

  <ng-container matColumnDef="orderNum">
    <th mat-header-cell *matHeaderCellDef> Order Number </th>
    <td mat-cell *matCellDef="let data"> {{data.orderNumber}} </td>
  </ng-container>


  <ng-container matColumnDef="webNum">
    <th mat-header-cell *matHeaderCellDef> Web Number </th>
    <td mat-cell *matCellDef="let data"> {{data.webNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef> Order Total </th>
    <td mat-cell *matCellDef="let data"> NIS {{ data.amount | number }} </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let data"> <a [routerLink]="" (click)="openOrder(data)">Edit</a> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>

<div class="mt-4 d-flex justify-content-center" *ngIf="dataSource.data.length > 0">
  <button mat-raised-button [disabled]="searchService.disableLoadMore" (click)="searchService.loadMore()">Load
    More</button>
</div>
