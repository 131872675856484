import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderOrigin } from '@vizodev/syntezza-sales-types/lib';

@Component({
  selector: 'app-select-origin',
  templateUrl: './select-origin.component.html',
  styleUrls: ['./select-origin.component.scss'],
})
export class SelectOriginComponent implements OnInit {
  origins = Object.values(OrderOrigin);

  selectedOrigin: OrderOrigin;
  updateIfExists = false;

  constructor(
    public dialogRef: MatDialogRef<SelectOriginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {}

  save() {
    this.dialogRef.close({
      origin: this.selectedOrigin,
      updateIfExists: this.updateIfExists,
    });
  }

  discard() {
    this.dialogRef.close();
  }
}
