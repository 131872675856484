import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss'],
})
export class AddContactDialogComponent implements OnInit {
  contactForm;

  constructor(
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      contactId: new FormControl(this.data?.contactId, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required]),
    });
  }

  onDiscard(): void {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close('DELETE');
  }

  save() {
    if (!this.contactForm.valid) return;

    const form = this.contactForm.value;
    if (this.data) {
      this.data.contactId = form.contactId;
      this.data.name = form.name;
    } else {
      this.data = {
        contactId: form.contactId,
        name: form.name,
      };
    }

    this.dialogRef.close(this.data);
  }
}
