import { Injectable, OnDestroy } from '@angular/core';
import { Organization } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';
import { OrganizationsService } from '../organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersService implements OnDestroy {
  private filtersSubject = new BehaviorSubject({
    prepay: [true, false],
    organization: null,
    pi: null,
    query: null,
  });
  private loadingSubject = new BehaviorSubject(true);
  private errorSubject = new BehaviorSubject(false);
  private reloadSubject = new BehaviorSubject(null);

  showAllFilters = true;

  allFilters: any = {
    prepay: [true, false],
    organization: null,
    pi: null,
    query: null,
  };

  public appliedFilters = {
    prepay: true,
    organization: null,
    pi: null,
    query: null,
  };

  orderByValue: string;
  direction: 'asc' | 'desc' = 'asc';

  sub = new Subscription();

  constructor(
    private orgService: OrganizationsService,
    private alert: AlertService
  ) {
    this.loadFilters();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public get error$(): Observable<boolean> {
    return this.errorSubject.asObservable();
  }

  public get reload$(): Observable<any> {
    return this.reloadSubject.asObservable();
  }

  public get filters$(): Observable<any> {
    return this.filtersSubject.asObservable();
  }

  setLoading(state: boolean) {
    this.loadingSubject.next(state);
  }

  setError(state: boolean) {
    this.errorSubject.next(state);
  }

  setFilters(filters: any) {
    this.filtersSubject.next(this.showAllFilters ? this.allFilters : filters);
  }

  orderBy(v: string, dir: 'asc' | 'desc') {
    this.orderByValue = v;
    this.direction = dir;
    this.reloadSearch();
  }

  changeOrganizationFilter(org: string) {
    this.appliedFilters.organization = org;
    this.reloadSearch();
  }

  changePiFilter(pi: string) {
    this.appliedFilters.pi = pi;
    this.reloadSearch();
  }

  changePrepayFilter(pp: any) {
    this.appliedFilters.prepay = pp;
    this.reloadSearch();
  }

  changeQueryFilter(q: string | number) {
    this.appliedFilters.query = q;
    this.reloadSearch();
  }

  reloadSearch() {
    this.reloadSubject.next({
      filters: this.appliedFilters,
      orderBy: this.orderByValue,
      direction: this.direction,
    });
  }

  async loadFilters() {
    this.setLoading(true);

    this.sub.add(this.orgService.organizations$.subscribe((organizations) => {
      this.allFilters = {
        organizations,
        prepay: [true, false],
      };
      this.setFilters(this.allFilters);

      this.setLoading(false);

      this.reloadSearch();
    }));
  }
}
