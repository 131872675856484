import { Injectable } from '@angular/core';
import { ContactPerson, Laboratory } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private contactsSubject = new BehaviorSubject<ContactPerson[]>([]);

  sub = new Subscription();

  labId: string;
  constructor(
    private database: DatabaseService,
    private alertService: AlertService
  ) {}

  public get contacts$() {
    return this.contactsSubject.asObservable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadContacts(labId: string) {
    if (this.labId === labId && this.contactsSubject.value.length) return;
    this.labId = labId;
    this.sub.add(
      this.database.getContactsFromLaboratory(labId).subscribe((contacts) => {
        //console.log('Contacts', contacts);
        this.contactsSubject.next(contacts ?? []);
      })
    );
  }

  async addContactPerson(lab: Laboratory, contactId: string, name: string) {
    const contact = new ContactPerson(null, contactId, lab.toInfo(), name);

    try {
      await this.database.createContactPerson(contact);
      this.alertService.success('');
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  async updateContactPerson(contact: ContactPerson) {
    try {
      await this.database.updateContactPerson(ContactPerson.fromMap(contact));
      this.alertService.success('');
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }

  async deleteContactPerson(contact: ContactPerson) {
    try {
      await this.database.deleteContactPerson(contact);
      this.alertService.success('');
    } catch (error) {
      this.alertService.error(JSON.stringify(error));
    }
  }
}
