<div class="container-fluid p-4" *ngIf="labForm" [formGroup]="labForm">

  <div class="row">
    <div class="col-md-7">

      <div class="row">
        <div class="form-group has-search labs-search col-md-4">
          <label for="piName">PI Name</label>
          <input type="text" class="form-control" placeholder="PI Name" formControlName="piName"
            onkeypress="return String.fromCharCode(event.charCode).match(/[\.\$\[\]\#\/]/g) === null">
        </div>
        <div class="form-group has-search labs-search col-md-4">
          <label for="bpCode">BP Code</label>
          <input type="text" class="form-control" type="number" placeholder="bpCode" formControlName="bpCode">
        </div>
        <div class="form-group has-search labs-search col-md-4">
          <label for="bpCemailode">Email</label>
          <input type="text" class="form-control" placeholder="Lab Email" formControlName="email">
        </div>
      </div>

      <div class="row">
        <div class="form-group has-search labs-search col-md-4">
          <label for="contactName">Contact Name</label>
          <input type="text" class="form-control" placeholder="Contact Name" formControlName="contactName">
        </div>
        <div class="form-group has-search labs-search col-md-4">
          <label for="phone">Phone</label>
          <input type="text" class="form-control" placeholder="Phone" formControlName="phone">
        </div>
      </div>

      <div class="row">

        <div class="col-md-6">
          <label for="organization">Organization</label>
          <mat-select class="form-control" formControlName="organization" placeholder="Organization" #singleSelect>
            <mat-option>
              <ngx-mat-select-search noEntriesFoundLabel="Nothing found" placeholderLabel="Type something"
                [formControl]="orgFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
              {{org.name}}
            </mat-option>
          </mat-select>
        </div>

        <div class="col-md-6 row">

          <mat-checkbox class="example-margin col-md-4 d-flex align-items-center" formControlName="hasPrepay">PrePay
          </mat-checkbox>
          <mat-checkbox class="example-margin col-md-4 d-flex align-items-center" formControlName="hasDiscount">Discount
          </mat-checkbox>
          <mat-checkbox class="example-margin col-md-4 d-flex align-items-center" formControlName="hasExtraCredit">Extra
            Credit</mat-checkbox>

        </div>

      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="buttons">
            <button mat-flat-button color="warn" (click)="delete()"> Delete </button>
            <button mat-stroked-button [disabled]="!labForm.dirty" (click)="discard()"> Cancel </button>
            <button mat-flat-button [disabled]="!(labForm.valid && labForm.dirty)" color="primary" (click)="save()">
              Save
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-5 d-flex flex-column justify-content-between">
      <div class="row mx-1">

        <mat-form-field style="width: 100%;">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName='notes'> </textarea>
        </mat-form-field>

      </div>

      <div *ngIf="balanceForm" class="initial-balance container" [formGroup]="balanceForm">

        <p>Initial Balance</p>

        <div class="row">

          <div class="col">

            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input matInput formControlName='amount' type="number">
            </mat-form-field>

          </div>

          <div class="col">

            <mat-form-field>
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" formControlName='date'>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </div>
        </div>

        <div class="buttons">

          <button mat-stroked-button [disabled]="!balanceForm.dirty" (click)="discardBalance()"> Cancel </button>
          <button mat-flat-button [disabled]="!(balanceForm.valid && balanceForm.dirty)" color="primary"
            (click)="saveBalance()">
            Save
          </button>

        </div>

      </div>
    </div>

  </div>

</div>

<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="contactID">
    <th mat-header-cell *matHeaderCellDef> Contact ID </th>
    <td mat-cell *matCellDef="let data"> {{data.contactId}} </td>
  </ng-container>

  <ng-container matColumnDef="contactName">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let data"> {{data.name}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let data"> <a [routerLink]="" (click)="editContact(data)">Edit</a> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table>
