<div class="container">

  <div class="warning">
    <h4>Before uploading, please make sure:</h4>
    <ul>
      <li>The columns follow the order: Sales Order #, Web Number, Order Date, Enduser #, Total Enduser Price</li>
      <li>The table is <b>formatted as text, not number, or currency</b></li>
    </ul>
  </div>

  <div class="dropzone" appdropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
    [class.hovering]="isHovering">
    <h3>Drop Zone</h3>
    <p>Drag and Drop a File</p>
    <button type="button" class="btn btn-primary" (click)="fileInput.click()">Choose File</button>
    <input type="file" multiple accept="application/csv" (change)="onFileSelected()" #fileInput id="file" hidden  />
  </div>

  <h3>Uploads</h3>

  <div *ngFor="let file of files">
    <app-upload-task [file]="file"></app-upload-task>
  </div>


</div>
