<h1 mat-dialog-title>Contact Person</h1>


<div mat-dialog-content [formGroup]="contactForm">

  <div class="row">
    <div class="col">

      <mat-form-field>
        <mat-label>Contact ID</mat-label>
        <input matInput formControlName="contactId">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <mat-form-field>
        <mat-label>First Name Last Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

    </div>
  </div>

</div>


<div mat-dialog-actions  class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="onDiscard()">Cancel</button>
  <button mat-flat-button color="warn" (click)="delete()">Delete</button>
  <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial>Save</button>
</div>
