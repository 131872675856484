<div class="container" style="background-color: transparent; box-shadow: none; ">

  <div class="row">
    <h3>Export Reports</h3>
  </div>

  <section [formGroup]="accountsForm" class=" container py-4 mt-4">

    <h5>Accounts</h5>
    <div class="row px-4">

      <mat-form-field class="col">
        <mat-label>PrePay</mat-label>
        <mat-select formControlName="prepay">
          <mat-option value="All">All</mat-option>
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col">
        <mat-label>Organization</mat-label>
        <mat-select  [formControl]="organizationControl" placeholder="Organization" #singleSelect>
          <mat-option>
            <ngx-mat-select-search noEntriesFoundLabel="Nothing found" placeholderLabel="Type something"
              [formControl]="orgFilter"></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
            {{org.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="col">
        <mat-label>Minimum Last Order Date</mat-label>
        <input matInput [matDatepicker]="pickerMin" formControlName='minLastOrderDate'>
        <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
        <mat-datepicker #pickerMin></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col">
        <mat-label>Maximum Last Order Date</mat-label>
        <input matInput [matDatepicker]="pickerMax" formControlName='maxLastOrderDate'>
        <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
        <mat-datepicker #pickerMax></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col">
        <mat-label>Max Balance</mat-label>
        <input matInput type="number" formControlName='maxBalance'>
      </mat-form-field>

    </div>

    <div class="row px-4 d-flex align-item-center justify-content-end">
      <button mat-raised-button (click)="exportAccounts()" [disabled]="exportingAccounts">Export</button>
    </div>

  </section>


  <section [formGroup]="ordersForm" class=" container py-4 mt-4">

    <h5>Orders</h5>
    <div class="row px-4">

      <mat-form-field class="col">
        <mat-label>IDT or Macrogen</mat-label>
        <mat-select formControlName="origin">
          <mat-option value="All">All</mat-option>
          <mat-option value="IDT">IDT</mat-option>
          <mat-option value="MACROGEN">MACROGEN</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col">
        <mat-label>Minimum Order Date</mat-label>
        <input matInput [matDatepicker]="pickerOrderMin" formControlName='minLastOrderDate'>
        <mat-datepicker-toggle matSuffix [for]="pickerOrderMin"></mat-datepicker-toggle>
        <mat-datepicker #pickerOrderMin></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col">
        <mat-label>Maximum Order Date</mat-label>
        <input matInput [matDatepicker]="pickerOrderMax" formControlName='maxLastOrderDate'>
        <mat-datepicker-toggle matSuffix [for]="pickerOrderMax"></mat-datepicker-toggle>
        <mat-datepicker #pickerOrderMax></mat-datepicker>
      </mat-form-field>

    </div>

    <div class="row px-4 d-flex align-item-center justify-content-end">
      <button mat-raised-button (click)="exportOrders()" [disabled]="exportingOrders">Export</button>
    </div>

  </section>


  <section class="container py-4 mt-4">

    <h5>Contacts</h5>

    <div class="row px-4 d-flex align-item-center justify-content-end">
      <button mat-raised-button (click)="exportContacts()" [disabled]="exportingContacts">Export</button>
    </div>

  </section>

</div>
