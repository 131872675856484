import { Component, OnInit } from '@angular/core';
import { RouterService } from 'src/app/core/router/router.service';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  tabs = [
    {
      name: 'Home',
      route: '/'
    },
    {
      name: 'Search Orders',
      route: '/search'
    },
    {
      name: 'Upload Orders',
      route: '/upload'
    },
    {
      name: 'Export',
      route: '/export'
    }
  ]

  constructor(private auth: UserAuthService, private router: RouterService) { }

  ngOnInit(): void {
  }

  logout() {
    return this.auth.logout();
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
