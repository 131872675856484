import { Injectable } from '@angular/core';
import { Order } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private ordersSubject = new BehaviorSubject<Order[]>([]);

  limit = 50;
  lastSnap: any;
  disableLoadMore = false;

  query = null;

  sub = new Subscription();
  constructor(
    private database: DatabaseService,
    private alertService: AlertService
  ) {}

  public get orders$() {
    return this.ordersSubject.asObservable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  changeQueryFilter(q: string) {
    this.query = q;
    this.search();
  }

  search() {
    this.disableLoadMore = false;
    this.sub.add(
      this.database
        .searchAllOrders(this.query, this.limit)
        .subscribe((orders) => {
          this.lastSnap = orders.length
            ? orders[orders.length - 1].payload.doc
            : null;
          this.ordersSubject.next(
            orders.map((o) => Order.fromFirestore(o.payload.doc))
          );
        })
    );
  }

  loadMore() {
    this.sub.add(
      this.database
        .searchAllOrders(this.query, this.limit, this.lastSnap)
        .pipe(take(1))
        .subscribe((orders) => {
          if (orders && orders.length) {
            this.lastSnap = orders[orders.length - 1].payload.doc;
            if (orders.length % this.limit === 0) this.disableLoadMore = false;
            else this.disableLoadMore = true;
            this.ordersSubject.next([
              ...this.ordersSubject.value,
              ...orders.map((o) => Order.fromFirestore(o.payload.doc)),
            ]);
          } else {
            this.disableLoadMore = true;
          }
        })
    );
  }

  clear() {
    this.ordersSubject.next([]);
  }
}
