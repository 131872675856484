<h1 mat-dialog-title>Edit Order</h1>
<div mat-dialog-content [formGroup]="orderForm">

  <div class="row">

    <div class="col-6">
      <mat-form-field>
        <mat-label>Order Number</mat-label>
        <input matInput formControlName="orderNumber">
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field>
        <mat-label>Web Number</mat-label>
        <input matInput formControlName="webNumber">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-6">
      <mat-form-field style="width: 100%;">
        <mat-label>Date</mat-label>
        <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>


    <div class="col-6">
      <mat-form-field>
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" type="number">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-6">

      <mat-form-field>
        <mat-label>Contact Person</mat-label>
        <mat-select formControlName='contactPerson' [compareWith]="contactPersonComparison">
          <mat-option *ngFor="let contact of contactService.contacts$ | async" [value]="contact">{{ contact.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>


    <div class="col-6">

      <mat-form-field>
        <mat-label>Supplier</mat-label>
        <mat-select formControlName='origin'>
          <mat-option *ngFor="let origin of origins" (click)="showOther = false" [value]="origin">{{ origin }}
          </mat-option>
          <mat-option [value]="null" (click)="showOther = true">Other</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </div>

  <div class="row" *ngIf="showOther">

    <div class="col-6">
      <mat-form-field>
        <mat-label>Other Supplier</mat-label>
        <input matInput (change)="setOrigin($event)">
      </mat-form-field>
    </div>

  </div>

</div>

<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="onDiscard()">Cancel</button>
  <button mat-flat-button *ngIf="data && data.date" color="warn" (click)="this.dialogRef.close('DELETE')"
    [disabled]="data.date.getTime() < minDate.getTime()">Delete</button>
  <button mat-flat-button color="primary" (click)="save()"
    [disabled]="(data && data.date && data.date.getTime() < minDate.getTime()) || !orderForm.valid || stringToDate(orderForm.value.date).getTime() < minDate.getTime()"
    cdkFocusInitial>Save</button>
</div>
