import { Injectable } from '@angular/core';
import { Balance, Laboratory, PrePay } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';

enum Views {
  ALL,
  LAST_YEAR,
  LAST_MONTH,
  LAST_PREPAY,
}
@Injectable({
  providedIn: 'root',
})
export class PrepaysService {
  private prepaysSubject = new BehaviorSubject<PrePay[]>([]);

  lab: Laboratory;
  initialBalance: Balance;
  limit = 50;
  lastSnap: any;
  disableLoadMore = false;

  view: Views = Views.ALL;

  sub = new Subscription();

  constructor(
    private database: DatabaseService,
    private alertService: AlertService
  ) {}

  public get prepays$() {
    return this.prepaysSubject.asObservable();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  viewToQuery(view: Views) {
    switch (view) {
      case Views.ALL:
        return null;
      case Views.LAST_MONTH:
        const d = new Date();
        const month = d.getMonth();
        if (month === 1) {
          d.setFullYear(d.getFullYear() - 1, 12, 1);
        } else d.setMonth(month - 1, 1);
        d.setHours(0, 0, 0, 0);
        return d;
      case Views.LAST_YEAR:
        const d1 = new Date();
        d1.setFullYear(d1.getFullYear() - 1, 0, 1);
        d1.setHours(0, 0, 0, 0);
        return d1;
      case Views.LAST_YEAR:
        return this.lab.lastPrepayInfo?.date;
    }
  }

  changeView(view: Views) {
    this.view = view;
    this.lastSnap = null;
    this.loadPrepays(this.lab, true);
  }

  loadPrepays(lab: Laboratory, reload?: boolean) {
    if (this.lab?.laboratoryId === lab?.laboratoryId && !reload) return;
    this.disableLoadMore = false;
    this.lab = lab;
    this.sub.add(
      this.database
        .getPrepaysFromLaboratory(
          lab.laboratoryId,
          this.limit,
          this.viewToQuery(this.view)
        )
        .subscribe((prepays) => {
          this.lastSnap = prepays.length
            ? prepays[prepays.length - 1].payload.doc
            : null;
          this.prepaysSubject.next(
            prepays.map((o) => PrePay.fromFirestore(o.payload.doc))
          );
        })
    );
  }

  loadMore() {
    this.sub.add(
      this.database
        .getPrepaysFromLaboratory(
          this.lab.laboratoryId,
          this.limit,
          this.viewToQuery(this.view),
          this.lastSnap
        )
        .subscribe((prepays) => {
          if (prepays.length) {
            this.lastSnap = prepays[prepays.length - 1].payload.doc;
            if (prepays.length % this.limit === 0) this.disableLoadMore = false;
            else this.disableLoadMore = true;
            this.prepaysSubject.next([
              ...this.prepaysSubject.value,
              ...prepays.map((o) => PrePay.fromFirestore(o.payload.doc)),
            ]);
          } else {
            this.disableLoadMore = true;
          }
        })
    );
  }

  async updatePrepay(prepay: PrePay) {
    try {
      await this.database.updatePrePay(PrePay.fromMap(prepay));
      this.alertService.success('');
    } catch (error) {
      console.error(error);
      this.alertService.error(JSON.stringify(error));
    }
  }

  async deletePrepay(prepay: PrePay) {
    try {
      await this.database.deletePrePay(prepay);
      this.alertService.success('');
    } catch (error) {
      console.error(error);
      this.alertService.error(JSON.stringify(error));
    }
  }

  clear() {
    this.lab = null;
    this.prepaysSubject.next([]);
  }
}
