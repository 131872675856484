import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ContactPerson, OrderOrigin } from '@vizodev/syntezza-sales-types/lib';

import { ContactsService } from 'src/app/services/contacts/contacts.service';

@Component({
  selector: 'app-add-order-dialog',
  templateUrl: './add-order-dialog.component.html',
  styleUrls: ['./add-order-dialog.component.scss'],
})
export class AddOrderDialogComponent implements OnInit {
  orderForm: FormGroup;

  origins = Object.values(OrderOrigin);

  otherOrigin = null;
  showOther = false;

  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<AddOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public contactService: ContactsService
  ) {
    this.minDate = data.minDate ?? new Date(new Date().getTime() - 5.184e9);
  }

  ngOnInit(): void {
    this.orderForm = new FormGroup({
      orderNumber: new FormControl(this.data?.orderNumber ?? null, [
        Validators.required,
      ]),
      webNumber: new FormControl(this.data?.webNumber ?? null, []),
      contactPerson: new FormControl(this.data?.contactPerson ?? null, [
        Validators.required,
      ]),
      date: new FormControl(this.data?.date, [Validators.required]),
      amount: new FormControl(this.data?.amount ?? 0, [Validators.required]),
      origin: new FormControl(this.data?.origin ?? null, []),
    });
  }

  contactPersonComparison(a: ContactPerson, b: ContactPerson) {
    return a?.contactPersonId === b?.contactPersonId;
  }

  dateToString(date: Date) {
    if (!date) return null;
    const d = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
    return d;
  }

  stringToDate(value: any) {
    try {
      return value.toDate();
    } catch (error) {
      return value;
    }
  }

  onDiscard(): void {
    this.dialogRef.close();
  }

  setOrigin(event: any) {
    this.orderForm.setControl('origin', new FormControl(event.target.value));
  }

  save() {
    if (!this.orderForm.valid) return;
    const form = this.orderForm.value;

    //console.log(form);

    if (this.data) {
      this.data.orderNumber = form.orderNumber;
      this.data.webNumber = form.webNumber;
      this.data.contactPerson = form.contactPerson;
      this.data.date = this.stringToDate(form.date);
      this.data.amount = form.amount;
      this.data.origin = form.origin;
    } else {
      this.data = {
        orderNumber: form.orderNumber,
        webNumber: form.webNumber,
        contactPerson: form.contactPerson,
        date: this.stringToDate(form.date),
        amount: form.amount,
        origin: form.origin,
      };
    }
    this.dialogRef.close(this.data);
  }
}
