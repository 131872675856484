import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss'],
})
export class AddOrganizationComponent implements OnInit {
  orgForm;

  constructor(
    public dialogRef: MatDialogRef<AddOrganizationComponent>,
    private orgService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.orgForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  onDiscard(): void {
    this.dialogRef.close();
  }

  async save() {
    if (!this.orgForm.valid) return;

    const res = await this.orgService.createOrganization(
      this.orgForm.value.name
    );

    if (res) {
      this.dialogRef.close();
    }
  }
}
