import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PrePay } from '@vizodev/syntezza-sales-types/lib';

@Component({
  selector: 'app-add-prepay-dialog',
  templateUrl: './add-prepay-dialog.component.html',
  styleUrls: ['./add-prepay-dialog.component.scss'],
})
export class AddPrepayDialogComponent implements OnInit {
  prepayForm;

  today = new Date();
  minDate;

  constructor(
    public dialogRef: MatDialogRef<AddPrepayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    //console.log(data.minDate);
    this.minDate = data?.minDate ?? new Date(new Date().getTime() - 5.184e9);
  }

  ngOnInit(): void {
    this.prepayForm = new FormGroup({
      sapInvoice: new FormControl(this.data?.sapInvoice ?? null, []),
      poNumber: new FormControl(this.data?.poNumber ?? null, []),
      amount: new FormControl(this.data?.amount ?? 0, [Validators.required]),
      extraAmount: new FormControl(this.data?.extraAmount ?? 0, [
        Validators.required,
      ]),
      date: new FormControl(this.data?.date ?? null, [Validators.required]),
      notes: new FormControl(this.data?.notes ?? null),
    });
  }

  public get totalAmount(): number {
    return this.prepayForm.value.amount + this.prepayForm.value.extraAmount;
  }

  onDiscard(): void {
    this.dialogRef.close();
  }

  stringToDate(value: any) {
    try {
      return value.toDate();
    } catch (error) {
      return value;
    }
  }

  save() {
    if (!this.prepayForm.valid) return;
    const form = this.prepayForm.value;

    if (this.data) {
      this.data.sapInvoice = form.sapInvoice;
      this.data.poNumber = form.poNumber;
      this.data.amount = form.amount;
      this.data.extraAmount = form.extraAmount;
      this.data.totalAmount = this.data.amount + this.data.extraAmount;
      this.data.date = this.stringToDate(form.date);
      this.data.notes = form.notes;
    } else {
      this.data = {
        sapInvoice: form.sapInvoice,
        poNumber: form.poNumber,
        amount: form.amount,
        extraAmount: form.extraAmount,
        totalAmount: form.amount + form.extraAmount,
        date: this.stringToDate(form.date),
        notes: form.notes,
      };
    }
    this.dialogRef.close(this.data);
  }
}
