import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input() pdfUrl: string;

  zoomValue = .7;
  rotation = 0;

  constructor() { }

  ngOnInit(): void {
  }

  rotate() {
    if (this.rotation === 180) this.rotation = 0;
    this.rotation += 90;
  }

  zoom(factor: number) {
    this.zoomValue += factor;
  }

  download() {
    const link = document.createElement('a');
    link.href = this.pdfUrl;
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

}
