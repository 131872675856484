import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Laboratory, Organization } from '@vizodev/syntezza-sales-types/lib';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { FiltersService } from 'src/app/services/filters/filters.service';
import { LabsService } from 'src/app/services/labs/labs.service';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';
import { PisService } from 'src/app/services/pis/pis.service';

@Component({
  selector: 'app-labs-list',
  templateUrl: './labs-list.component.html',
  styleUrls: ['./labs-list.component.scss'],
})
export class LabsListComponent implements OnInit, OnDestroy {
  @Input() labs$: Observable<Laboratory[]>;
  @Input() disableLoadMore: boolean = false;
  @Output() onClick = new EventEmitter();

  columnsToDisplay = [
    'bpCode',
    'organizationName',
    'piName',
    'balance',
    'lastPrepayDate',
    'prepayAmount',
    'lastOrderDate',
    'actions',
  ];

  orderByValue = 'bpCode';
  direction: 'asc' | 'desc' = 'asc';
  click = 1;

  dataSource = new MatTableDataSource<Laboratory>([]);


  filters$: Observable<any>;

  organizationControl = new FormControl(null);
  piControl = new FormControl(null);
  prepayControl = new FormControl(true);
  queryControl = new FormControl(null);

  organizations: Organization[] = [];
  pis: string[] = [];
  orgFilter = new FormControl();
  piFilter = new FormControl();

  filteredOrganizations = new ReplaySubject<Organization[]>(1);
  filteredPis = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  subscriptions = new Subscription();


  constructor(private filterService: FiltersService, private orgService: OrganizationsService, private pisService: PisService, public labsService: LabsService) {}

  ngOnInit(): void {
    this.filters$ = this.filterService.filters$;

    this.subscriptions.add(
      this.labs$.subscribe((labs) => {
        this.dataSource = new MatTableDataSource<Laboratory>(labs);
      })
    );

    this.subscriptions.add(
      this.organizationControl.valueChanges.subscribe((org) => {
        this.filterService.changeOrganizationFilter(
          org ? org.organizationId : null
        );
      })
    );

    this.subscriptions.add(
      this.piControl.valueChanges.subscribe((pi) => {
        this.filterService.changePiFilter(
          pi ? pi : null
        );
      })
    );

    this.subscriptions.add(
      this.prepayControl.valueChanges.subscribe((pp) => {
        this.filterService.changePrepayFilter(pp);
      })
    );

    this.subscriptions.add(
      this.queryControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((q: any) => {
          const a = parseInt(`${q}`);
          this.filterService.changeQueryFilter(isNaN(a) ? q : a);
        })
    );


    this.subscriptions.add(this.orgService.organizations$.subscribe(orgs => {
      this.organizations = orgs;
      this.filteredOrganizations.next(orgs.slice());
    }))

    this.subscriptions.add(this.pisService.pis$.subscribe(pis => {
      this.pis = pis;
      this.filteredPis.next(pis.slice());
    }))

    this.orgFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrgs();
      });

      this.piFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPis();
      });
  }

  protected filterOrgs() {
    if (!this.organizations) {
      return;
    }
    // get the search keyword
    let search = this.orgFilter.value;
    if (!search) {
      this.filteredOrganizations.next(this.organizations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOrganizations.next(
      this.organizations.filter(o => o.name.toLowerCase().startsWith(search))
    );
  }

  protected filterPis() {
    if (!this.pis) {
      return;
    }
    // get the search keyword
    let search = this.piFilter.value;
    if (!search) {
      this.filteredPis.next(this.pis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredPis.next(
      this.pis.filter(o => o.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete()
  }

  orderBy(v: string) {
    if (this.orderByValue ===  v) {
      if (this.click === 0) {
        this.click++;
        this.setDirection();
      } else if (this.click === 1) {
        this.click = 0;
        this.setDirection();
      }
    } else {
      this.setDirection(true);
      this.orderByValue = v;
    }
    this.filterService.orderBy(this.orderByValue, this.direction);
  }

  setDirection(reset?: boolean) {
    this.direction = reset ? 'asc' : this.direction ===  'asc' ? 'desc' : 'asc';
  }

  loadMore() {
    this.onClick.emit({
      action: 'LOAD_MORE',
    });
  }

  openLab(lab: Laboratory) {
    this.onClick.emit({
      action: 'OPEN',
      lab,
    });
  }

  createLab() {
    this.onClick.emit({
      action: 'CREATE',
    });
  }
}
