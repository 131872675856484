<div class="row ">
  <div class="col-12 d-flex flex-row-reverse my-2">
    <mat-button-toggle-group name="view" [(ngModel)]="ordersService.view" #group="matButtonToggleGroup">
      <mat-button-toggle [value]="0" (change)="changeView(0)">All</mat-button-toggle>
      <mat-button-toggle [value]="1" (change)="changeView(1)" [disabled]="true">12 months</mat-button-toggle>
      <mat-button-toggle [value]="2" (change)="changeView(2)" [disabled]="true">Last month</mat-button-toggle>
      <mat-button-toggle [value]="3" (change)="changeView(3)" [disabled]="true">Last prepay</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<table class="table table-light">

  <thead class="thead-light">
    <tr>
      <th>End-user name</th>
      <th>Date</th>
      <th>Supplier</th>
      <th>Order Number</th>
      <th>Web Number</th>
      <th>Order Total</th>
      <th>Balance</th>
      <th>Actions</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let data of dataSource.data; let i = index">

      <tr *ngIf="i == 0" class="gray">
        <td> Initial Balance </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>NIS {{ data.balance | number }} </td>
        <td>
          <button mat-button (click)="editIntialBalance()" *ngIf="ordersService.view === 0">Edit</button>
        </td>
      </tr>

      <tr *ngIf="i > 0 && isOrder(data)">
        <td>{{data.contactPerson.name}} </td>
        <td>{{data.date | date: 'dd/MMM/yyyy'}} </td>
        <td>{{data.origin}}</td>
        <td>{{data.orderNumber}}</td>
        <td>{{data.webNumber}}</td>
        <td>NIS {{ data.amount | number }}</td>
        <td>NIS {{ data.balance | number }}</td>
        <td> <a [routerLink]="" (click)="editOrder(data)">Edit</a> </td>
      </tr>

      <tr *ngIf="i > 0 && !isOrder(data)" class="gray">
        <td> PrePay </td>
        <td>{{data.date | date: 'dd/MMM/yyyy'}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>NIS {{ data.amount | number }} + NIS {{data.extraAmount}}</td>
        <td>NIS {{ data.balance | number }}</td>
        <td></td>
      </tr>
    </ng-container>
  </tbody>

</table>


<!-- <table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="contactPerson">
    <th mat-header-cell *matHeaderCellDef> End-user name </th>
    <td mat-cell *matCellDef="let data"> {{data.contactPerson.name}} </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let data"> {{data.date | date: 'dd/MMM/yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="origin">
    <th mat-header-cell *matHeaderCellDef> Supplier </th>
    <td mat-cell *matCellDef="let data"> {{data.origin}} </td>
  </ng-container>

  <ng-container matColumnDef="orderNum">
    <th mat-header-cell *matHeaderCellDef> Order Number </th>
    <td mat-cell *matCellDef="let data"> {{data.orderNumber}} </td>
  </ng-container>


  <ng-container matColumnDef="webNum">
    <th mat-header-cell *matHeaderCellDef> Web Number </th>
    <td mat-cell *matCellDef="let data"> {{data.webNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef> Order Total </th>
    <td mat-cell *matCellDef="let data"> NIS {{ data.amount | number }} </td>
  </ng-container>

  <ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef> Balance </th>
    <td mat-cell *matCellDef="let data"> NIS {{ (data.previousBalance - data.amount) | number }} </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let data"> <a [routerLink]="" (click)="editOrder(data)">Edit</a> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</table> -->

<div class="mt-4 d-flex justify-content-center">
  <button mat-raised-button [disabled]="ordersService.disableLoadMore" (click)="loadMore()">Load
    More</button>
</div>
