import { Injectable, OnDestroy } from '@angular/core';
import { Organization } from '@vizodev/syntezza-sales-types/lib';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService implements OnDestroy {
  private organizationsSubject = new BehaviorSubject<Organization[]>([]);

  sub = new Subscription();

  constructor(
    private database: DatabaseService,
    private alertService: AlertService
  ) {
    this.getOrganizations();
  }

  ngOnDestroy() {}

  public get organizations$() {
    return this.organizationsSubject.asObservable();
  }

  public get organizations() {
    return this.organizationsSubject.value;
  }

  getOrganizations() {
    this.sub.add(
      this.database.getOrganizations().subscribe((orgs) => {
        //console.log('Orgs', orgs)
        this.organizationsSubject.next(orgs ?? []);
      })
    );
  }

  async createOrganization(name: string) {
    try {
      await this.database.createOrganization(new Organization(null, name));
      this.alertService.success('Organization created');
      return true;
    } catch (error) {
      console.error(error);
      this.alertService.error(JSON.stringify(error));
      return false;
    }
  }
}
