<h1 mat-dialog-title>From date</h1>
<div mat-dialog-content>

  <div class="row">


    <div class="col-12">

      <mat-form-field style="width: 100%;">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>

  </div>

</div>

<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="dialogRef.close()">From last Prepay</button>
  <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial>Export</button>
</div>
