import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import {
  Balance,
  ContactPerson,
  Laboratory,
  Organization,
} from '@vizodev/syntezza-sales-types/lib';

import * as moment from 'moment';

import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { DatabaseService } from 'src/app/core/database/database.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { LabsService } from 'src/app/services/labs/labs.service';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

import { AddContactDialogComponent } from '../../../components/dialogs/add-contact-dialog/add-contact-dialog.component';

@Component({
  selector: 'app-lab-details',
  templateUrl: './lab-details.component.html',
  styleUrls: ['./lab-details.component.scss'],
})
export class LabDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) singleSelect: MatSelect;
  subscriptions = new Subscription();

  columnsToDisplay = ['contactID', 'contactName', 'actions'];

  lab: Laboratory;
  initialBalance: Balance;

  dataSource = new MatTableDataSource<ContactPerson>([]);

  labForm: FormGroup;
  balanceForm: FormGroup;

  organizations: Organization[] = [];
  orgFilter = new FormControl();

  filteredOrganizations = new ReplaySubject<Organization[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    private labsService: LabsService,
    private alertService: AlertService,
    public orgService: OrganizationsService,
    private dialog: MatDialog,
    private contactsService: ContactsService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.labsService.currentLab$.subscribe((lab) => {
        this.lab = Laboratory.fromMap(lab.toMap());
        this.fillLabForm(this.lab);
        this.contactsService.loadContacts(this.lab.laboratoryId);
      })
    );

    this.subscriptions.add(
      this.labsService.currentInitialBalance$.subscribe((b) => {
        this.initialBalance =
          b && b.labInfo.laboratoryId === this.lab.laboratoryId
            ? Balance.fromMap(b.toMap())
            : new Balance('initial', this.lab?.toInfo(), new Date(), 0, 0);
        this.fillBalanceForm(this.initialBalance);
      })
    );

    this.subscriptions.add(
      this.contactsService.contacts$.subscribe((contacts) => {
        this.dataSource = new MatTableDataSource<ContactPerson>(contacts);
      })
    );

    this.subscriptions.add(
      this.orgService.organizations$.subscribe((orgs) => {
        this.organizations = orgs;
        this.filteredOrganizations.next(orgs.slice());
      })
    );

    this.orgFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrgs();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredOrganizations
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Organization, b: Organization) =>
          a && b && a.organizationId === b.organizationId;
      });
  }

  fillLabForm(lab: Laboratory) {
    this.labForm = new FormGroup({
      piName: new FormControl(lab.pi.name, [Validators.required]),
      bpCode: new FormControl(lab.bpCode, [Validators.required, Validators.min(0)]),
      email: new FormControl(lab.email, [Validators.email]),
      phone: new FormControl(lab.phone, []),
      contactName: new FormControl(lab.contactName, [Validators.required]),
      organization: new FormControl(lab.organization, [Validators.required]),
      notes: new FormControl(lab.notes, []),
      hasPrepay: new FormControl(lab.hasPrepay, [Validators.required]),
      hasExtraCredit: new FormControl(lab.hasExtraCredit, [
        Validators.required,
      ]),
      hasDiscount: new FormControl(lab.hasDiscount, [Validators.required]),
    });
  }

  fillBalanceForm(balance: Balance) {
    this.balanceForm = new FormGroup({
      amount: new FormControl(balance.currentBalance, [Validators.required]),
      date: new FormControl(balance.date, [Validators.required]),
    });
  }

  orgComparison(a: Organization, b: Organization) {
    return a.organizationId === b.organizationId;
  }

  async save() {
    if (!this.labForm.valid) {
      this.alertService.error('Invalid form');
      return;
    }

    if (!this.lab) return;

    const form = this.labForm.value;

    this.lab.pi.name = form.piName;
    this.lab.bpCode = parseInt(form.bpCode);
    this.lab.email = form.email;
    this.lab.phone = form.phone;
    this.lab.contactName = form.contactName;
    this.lab.organization = form.organization ?? this.lab.organization;
    this.lab.notes = form.notes;
    this.lab.hasPrepay = form.hasPrepay;
    this.lab.hasDiscount = form.hasDiscount;
    this.lab.hasExtraCredit = form.hasExtraCredit;

    return await this.labsService.updateLab(this.lab);
  }

  async saveBalance() {
    if (!this.balanceForm.valid) {
      this.alertService.error('Invalid form');
      return;
    }

    const form = this.balanceForm.value;

    this.initialBalance.currentBalance = form.amount;

    if (moment.isMoment(form.date)) {
      this.initialBalance.date = form.date.toDate();
    } else {
      this.initialBalance.date = form.date;
    }

    return await this.labsService.updateInitialBalance(this.initialBalance);
  }

  protected filterOrgs() {
    if (!this.organizations) {
      return;
    }
    // get the search keyword
    let search = this.orgFilter.value;
    if (!search) {
      this.filteredOrganizations.next(this.organizations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOrganizations.next(
      this.organizations.filter((o) => o.name.toLowerCase().startsWith(search))
    );
  }

  discard() {
    this.fillLabForm(this.labsService.currentLab);
  }

  discardBalance() {
    this.fillBalanceForm(
      this.labsService.currentInitialBalance
        ? this.labsService.currentInitialBalance
        : new Balance('initial', this.lab.toInfo(), new Date(), 0, 0)
    );
  }

  async delete() {
    if (confirm('Are you sure you want to delete this lab?')) {
      await this.labsService.deleteLab(this.labsService.currentLab);
    }
  }

  editContact(contact: ContactPerson) {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      width: '440px',
      data: contact,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result === 'DELETE') {
          return this.deleteContact(contact);
        }

        this.labsService.updateContactPerson(result);
      }
    });
  }

  async deleteContact(contact: ContactPerson) {
    if (
      !confirm(
        'Are you sure you want to delete the contact: ' + contact.name + ' ?'
      )
    )
      return;

    await this.labsService.deleteContactPerson(contact);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
