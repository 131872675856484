import { Injectable, OnDestroy } from '@angular/core';
import { Event, Router } from '@angular/router';
import { Laboratory, Order } from '@vizodev/syntezza-sales-types/lib';
import { Observable, Subscription } from 'rxjs';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService implements OnDestroy {
  urlHistory: string[] = ['/'];

  subscription = new Subscription();

  private _preventNavigation = {
    prevent: false,
    message: null,
  };

  constructor(private router: Router) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public get currentRoute(): string {
    return this.router.url;
  }

  public get onChangeRoute(): Observable<Event> {
    return this.router.events;
  }


  async navigateByUrl(url: string) {
    if (this._preventNavigation.prevent) {
      if (!confirm(this._preventNavigation.message)) return false;
    }

    this.clearPreventNavigation();

    try {
      await this.router.navigateByUrl(url);
      this.urlHistory.push(this.currentRoute);
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  setPreventNavigation(prevent: boolean, message: string) {
    this._preventNavigation = {
      prevent,
      message,
    };
  }

  clearPreventNavigation() {
    this._preventNavigation = {
      prevent: false,
      message: null,
    };
  }

  back() {
    this.navigateByUrl(this.urlHistory.shift());
  }

  goToHome() {
    this.navigateByUrl('/');
  }

  goToLogin() {
    this.navigateByUrl('/login');
  }

  goToLab(lab: Laboratory) {
    if (this.currentRoute.indexOf(lab.laboratoryId) !== -1) return;
    this.navigateByUrl('/' + lab.laboratoryId);
  }

  openOrder(order: Order) {
    this.navigateByUrl(`/${order.laboratoryInfo.laboratoryId}/orders/${order.orderId}`);
  }
}
