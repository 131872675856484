import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderOrigin } from '@vizodev/syntezza-sales-types/lib';
import { Subscription } from 'rxjs';
import { CsvImporter } from 'src/app/classes/csv/csvImporter';
import { DatabaseService } from 'src/app/core/database/database.service';
import { RouterService } from 'src/app/core/router/router.service';
import { SelectOriginComponent } from 'src/app/modules/dashboard/components/dialogs/select-origin/select-origin.component';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  uploadingCount = 0;

  constructor(
    private router: RouterService,
    private database: DatabaseService
  ) {
    router.onChangeRoute.subscribe(() => (this.uploadingCount = 0));
  }

  uploadCSV(file: File, origin: OrderOrigin, updateIfExists: boolean) {
    const csvImporter = new CsvImporter(file, this.database);
    csvImporter.upload(origin, updateIfExists);
    return { percentage: csvImporter.percentage$, task: csvImporter.task$ };
  }

  preventRouter() {
    //console.log('Preventing router', this.uploadingCount);
    if (this.uploadingCount > 0) {
      this.router.setPreventNavigation(
        true,
        'Are you sure you want to leave? You are still uploading files'
      );
    } else {
      this.router.clearPreventNavigation();
    }
  }

  addUpload() {
    this.uploadingCount += 1;
    this.preventRouter();
  }

  subtractUpload() {
    this.uploadingCount -= 1;
    this.preventRouter();
  }
}
