import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { Organization } from '@vizodev/syntezza-sales-types/lib';

import * as moment from 'moment';

import {
  Observable,
  ReplaySubject,
  Subject,
  Subscriber,
  Subscription,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

import { AddOrganizationComponent } from '../add-organization/add-organization.component';

export interface LabDialogData {
  contactName: string;
  bpCode: number;
  email: string;
  organization: Organization;
  prepay: boolean;
  discount: boolean;
  extraCredit: boolean;
  notes: string;
}

@Component({
  selector: 'app-add-lab-dialog',
  templateUrl: './add-lab-dialog.component.html',
  styleUrls: ['./add-lab-dialog.component.scss'],
})
export class AddLabDialogComponent implements OnInit, OnDestroy {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  labForm = new FormGroup({
    contactName: new FormControl('', []),
    bpCode: new FormControl(null, [Validators.required, Validators.min(0)]),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl('', [Validators.required]),
    organization: new FormControl(null, [Validators.required]),
    prepay: new FormControl(false, [Validators.required]),
    discount: new FormControl(false, [Validators.required]),
    extraCredit: new FormControl(false, [Validators.required]),
    notes: new FormControl(''),
    piName: new FormControl('', [Validators.required]),
    contactId: new FormControl('', []),
    initialBalance: new FormControl(0, [Validators.required]),
    initialBalanceDate: new FormControl(null, [Validators.required]),
  });

  organizations: Organization[] = [];
  orgFilter = new FormControl();

  filteredOrganizations = new ReplaySubject<Organization[]>(1);
  sub = new Subscription();
  protected _onDestroy = new Subject<void>();

  constructor(
    public orgService: OrganizationsService,
    public dialogRef: MatDialogRef<AddLabDialogComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.orgService.organizations$.subscribe((orgs) => {
        this.organizations = orgs;
        this.filteredOrganizations.next(orgs.slice());
      })
    );

    this.orgFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrgs();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.sub.unsubscribe();
  }

  protected filterOrgs() {
    if (!this.organizations) {
      return;
    }
    // get the search keyword
    let search = this.orgFilter.value;
    if (!search) {
      this.filteredOrganizations.next(this.organizations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOrganizations.next(
      this.organizations.filter((o) => o.name.toLowerCase().startsWith(search))
    );
  }

  onDiscard(): void {
    this.dialogRef.close();
  }

  addOrganization() {
    this.dialog.open(AddOrganizationComponent, {
      width: '440px',
    });
  }

  save() {
    let initialBalanceDate: Date;
    if (moment.isMoment(this.labForm.value.initialBalanceDate)) {
      initialBalanceDate = this.labForm.value.initialBalanceDate.toDate();
    } else {
      initialBalanceDate = this.labForm.value.initialBalanceDate;
    }
    if (this.labForm.valid)
      this.dialogRef.close({ ...this.labForm.value, bpCode: parseInt(this.labForm.value.bpCode), initialBalanceDate });
  }
}
