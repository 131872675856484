<h1 mat-dialog-title>From where are these orders? <br> File {{ data?.filename }}</h1>
<div mat-dialog-content>

  <div class="row">


    <div class="col-12">

      <mat-form-field>
        <mat-label>Supplier</mat-label>
        <mat-select [(ngModel)]="selectedOrigin">
          <mat-option *ngFor="let origin of origins" [value]="origin">{{ origin }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="col-12">
      <mat-checkbox [(ngModel)]="updateIfExists" value="" color="primary">Update if exists?</mat-checkbox>
    </div>

  </div>

</div>

<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="discard()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial
    [disabled]="!selectedOrigin">Import</button>
</div>
