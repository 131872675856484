import { Component } from '@angular/core';
import { version } from '../../package.json';
import { UserAuthService } from './services/user-auth/user-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BaseProject';

  version = version

  constructor(private auth: UserAuthService) {
    this.auth.userLoginStream();
  }
}
