import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Balance, Order, PrePay } from '@vizodev/syntezza-sales-types/lib';

import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { map, mergeAll, takeUntil, tap, filter } from 'rxjs/operators';

import { DatabaseService } from 'src/app/core/database/database.service';
import { RouterService } from 'src/app/core/router/router.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LabsService } from 'src/app/services/labs/labs.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PrepaysService } from 'src/app/services/prepays/prepays.service';

import { AddOrderDialogComponent } from '../../../components/dialogs/add-order-dialog/add-order-dialog.component';

@Component({
  selector: 'app-lab-orders',
  templateUrl: './lab-orders.component.html',
  styleUrls: ['./lab-orders.component.scss'],
})
export class LabOrdersComponent implements OnInit {
  subscriptions = new Subscription();

  columnsToDisplay = [
    'contactPerson',
    'date',
    'origin',
    'orderNum',
    'webNum',
    'amount',
    'balance',
    'actions',
  ];

  dataSource = new MatTableDataSource<any>([]);

  constructor(
    public ordersService: OrdersService,
    private prepayService: PrepaysService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: RouterService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.prepayService.prepays$, this.ordersService.orders$])
        .pipe(
          map((data) => [].concat(...data)),
          tap((data: Array<Order | PrePay>) =>
            data.sort((a, b) => {
              if (a.date.getTime() > b.date.getTime()) return 1;
              if (a.date.getTime() < b.date.getTime()) return -1;

              return a instanceof PrePay && b instanceof Order
                ? -1
                : a instanceof Order && b instanceof PrePay
                ? 1
                : a instanceof PrePay && b instanceof PrePay
                ? b.poNumber > a.poNumber
                  ? -1
                  : 1
                : a instanceof Order && b instanceof Order
                ? b.orderNumber > a.orderNumber
                  ? -1
                  : 1
                : -1;
            })
          ),
          map(async (data) => {
            return new Promise((res) => {
              const first = data[0];
              if (first) {
                this.ordersService.labService.currentInitialBalance$
                  .pipe(
                    takeUntil(
                      this.ordersService.labService.currentInitialBalance$.pipe(
                        filter((balance) => !balance)
                      )
                    )
                  )
                  .subscribe((balance) => {
                    console.log('Balance', balance);

                    res(
                      [
                        {
                          balance: balance.currentBalance,
                        } as any,
                      ].concat(data)
                    );
                  });
              } else {
                res(data);
              }
            });
          }),
          mergeAll(),
          map((data: any) => {
            console.log(data);

            return data.map((e, i, a) => {
              if (e instanceof Order) {
                e['balance'] = a[i - 1].balance - e.amount;
              } else if (e instanceof PrePay) {
                e['balance'] = a[i - 1].balance + e.totalAmount;
              }
              return e;
            });
          })
        )
        .subscribe(
          (data: any) => (this.dataSource = new MatTableDataSource<any>(data))
        )
    );

    this.parseUrl();
  }

  isOrder(data: Order | PrePay) {
    return data instanceof Order;
  }

  parseUrl() {
    const route = this.router.currentRoute.substr(1).split('/');
    const orderId = route.length === 3 ? route.pop() : null;
    if (orderId) this.openOrderId(orderId);
  }

  async openOrderId(orderId: string) {
    const order = await this.ordersService.getOrderById(orderId);
    if (order) {
      this.editOrder(order);
    }
  }

  editIntialBalance() {
    const value = prompt('Type the new amount');
    if (value === null || value === undefined) return;
    if (isNaN(parseFloat(value))) {
      this.alertService.error('Invalid number');
      return;
    }
    return this.ordersService.updateInitialBalance(parseFloat(value));
  }

  editOrder(order: Order) {
    const dialogRef = this.dialog.open(AddOrderDialogComponent, {
      width: '440px',
      data: {
        ...order.toMap(),
        minDate: this.ordersService.initialBalance.date,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result === 'DELETE') {
          return this.deleteOrder(order);
        }
        this.ordersService.updateOrder(result);
      }
    });
  }

  deleteOrder(order: Order) {
    if (!confirm('Are you sure you want to delete this order?')) return;
    return this.ordersService.deleteOrder(order);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeView(v: number) {
    this.ordersService.changeView(v);
    this.prepayService.changeView(v);
  }

  loadMore() {
    this.ordersService.loadMore();
    this.prepayService.loadMore();
  }
}
