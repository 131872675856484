import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  exporting = false;

  constructor(private api: ApiService, private alertService: AlertService) {}

  stringToDate(value: any) {
    try {
      return value.toDate();
    } catch (error) {
      return value;
    }
  }

  async exportAccounts(filters: any) {
    this.exporting = true;
    let res;
    try {
      res = await this.api.exportAccounts(
        filters.prepay === 'All'
          ? null
          : filters.prepay === true || filters.prepay === 'true',
        filters.organization === 'All' ? null : filters.organization ?? null,
        filters.minLastOrderDate ? this.stringToDate(filters.minLastOrderDate).getTime() : null,
        filters.maxLastOrderDate ? this.stringToDate(filters.maxLastOrderDate).getTime() : null,
        filters.maxBalance
      );
      const blob = new Blob([res], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      var downloadLink = document.createElement('a');

      downloadLink.href = url;
      downloadLink.download = `accounts_${new Date().toDateString()}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      var dec = new TextDecoder();
      this.alertService.error(dec.decode(error));
    }
    this.exporting = false;
  }

  async exportOrders(filters: any) {
    this.exporting = true;
    let res;
    try {
      res = await this.api.exportOrders(
        filters.origin === 'All' ? null : filters.origin ?? null,
        filters.minLastOrderDate ? this.stringToDate(filters.minLastOrderDate).getTime() : null,
        filters.maxLastOrderDate ? this.stringToDate(filters.maxLastOrderDate).getTime() : null
      );
      const blob = new Blob([res], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      var downloadLink = document.createElement('a');

      downloadLink.href = url;
      const d = new Date();
      downloadLink.download = `Prepay ${filters.origin} Orders ${d.getDate()}-${
        d.getMonth() + 1
      }-${d.getFullYear()}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      var dec = new TextDecoder();
      this.alertService.error(dec.decode(error));
    }
    this.exporting = false;
  }

  async exportContacts() {
    this.exporting = true;
    try {
      const res: any = await this.api.exportContacts();

      const blob = new Blob([res], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      var downloadLink = document.createElement('a');

      downloadLink.href = url;
      downloadLink.download = `contacts_${new Date().toDateString()}.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      var dec = new TextDecoder();
      this.alertService.error(dec.decode(error));
    }
    this.exporting = false;
  }
}
