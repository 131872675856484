<h1 mat-dialog-title>Add Organization</h1>


<div mat-dialog-content [formGroup]="orgForm">

  <div class="row">
    <div class="col">

      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

    </div>
  </div>

</div>


<div mat-dialog-actions  class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="onDiscard()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial>Save</button>
</div>
