<h1 mat-dialog-title>Error Report</h1>
<div mat-dialog-content>

  <textarea [(ngModel)]="formattedText"></textarea>

  <mat-label *ngIf="message">{{message}}</mat-label>
</div>
<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="this.dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="copy()" cdkFocusInitial>Copy</button>
</div>
