import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Laboratory } from '@vizodev/syntezza-sales-types/lib';
import { LabsService } from 'src/app/services/labs/labs.service';
import {
  AddLabDialogComponent,
  LabDialogData,
} from '../../components/dialogs/add-lab-dialog/add-lab-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(public labsService: LabsService, public dialog: MatDialog) {}

  ngOnInit(): void {}

  onClick(event: any) {
    //console.log('Event', event);
    if (event.action === 'OPEN') return this.openLab(event.lab);
    if (event.action === 'CREATE') return this.createLab();
    if (event.action === 'LOAD_MORE') return this.labsService.loadMore();
  }

  openLab(lab: Laboratory) {
    this.labsService.open(lab);
  }

  createLab() {
    let labDialogData: LabDialogData;

    const dialogRef = this.dialog.open(AddLabDialogComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const {
          bpCode,
          contactName,
          discount,
          email,
          phone,
          extraCredit,
          notes,
          organization,
          prepay,
          piName,
          initialBalance,
          contactId,
          initialBalanceDate,
        } = result;
        this.labsService.createLab(
          bpCode,
          contactName,
          discount,
          email,
          phone,
          extraCredit,
          notes,
          organization,
          prepay,
          piName,
          initialBalance,
          contactId,
          initialBalanceDate
        );
      }
    });
  }
}
