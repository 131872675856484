<ng-container *ngIf="lab">

  <div class="header-container">
    <h2>{{lab.pi.name}} ({{lab.bpCode}})</h2>
    <div class="buttons">
      <button type="button" mat-raised-button [disabled]="labsService.exporting" (click)="exportReport()"
        class="btn btn-secondary">Lab Order Report</button>
        <button type="button" mat-raised-button (click)="recalculateBalance()" class="btn btn-secondary">Recalculate Balance</button>
        <button type="button" mat-raised-button (click)="openOrderDialog()" class="btn btn-secondary">Add Order</button>
      <button type="button" mat-raised-button (click)="openPrepayDialog()" class="btn btn-secondary">Add PrePay</button>
      <button type="button" mat-raised-button (click)="openContactDialog()" class="btn btn-secondary">Add
        Contact</button>
    </div>
  </div>
  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of labNavLinks; let i = index" [routerLink]="['./', link.link]" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive || activeLinkIndex === i">
      {{link.label}}
    </a>
  </nav>

  <router-outlet></router-outlet>

</ng-container>
