<h1 mat-dialog-title>Add Laboratory</h1>
<div mat-dialog-content [formGroup]="labForm">

  <section class="mb-4">
    <h4>Lab Details</h4>


    <div class="row">

      <div class="col">

        <mat-form-field>
          <mat-label>Organization</mat-label>

          <mat-select class="form-control" formControlName="organization" placeholder="Organization" #singleSelect>
            <mat-option>
              <ngx-mat-select-search noEntriesFoundLabel="Nothing found" placeholderLabel="Type something"
                [formControl]="orgFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]=null (click)="addOrganization()">
              <mat-icon>add</mat-icon>
              Add Organization
            </mat-option>
            <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
              {{org.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>

    <div class="row">


      <div class="col">

        <mat-form-field>
          <mat-label>Lab Email</mat-label>
          <input matInput formControlName='email'>
        </mat-form-field>
      </div>


      <div class="col">

        <mat-form-field>
          <mat-label>Phone</mat-label>
          <input matInput formControlName='phone'>
        </mat-form-field>
      </div>
    </div>

    <div class="row">

      <div class="col">

        <mat-form-field>
          <mat-label>BP Code</mat-label>
          <input matInput formControlName='bpCode' type="number">
        </mat-form-field>

      </div>

      <div class="col d-flex align-items">

        <mat-checkbox class="example-margin col-md-4" formControlName='prepay'>PrePay</mat-checkbox>
        <mat-checkbox class="example-margin col-md-4" formControlName='discount'>Discount</mat-checkbox>
        <mat-checkbox class="example-margin col-md-4" formControlName='extraCredit'>Extra Credit</mat-checkbox>

      </div>
    </div>


    <div class="row">

      <div class="col">

        <mat-form-field>
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName='notes'> </textarea>
        </mat-form-field>

      </div>
    </div>
  </section>


  <section class="mb-4">
    <h4>Initial Balance</h4>


    <div class="row">

      <div class="col">

        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input matInput formControlName='initialBalance' type="number">
        </mat-form-field>

      </div>

      <div class="col">


        <mat-form-field>
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName='initialBalanceDate'>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>


      </div>

    </div>

  </section>

  <section class="mb-4">
    <h4>PI</h4>

    <mat-form-field>
      <mat-label>Last Name First Name</mat-label>
      <input matInput formControlName='piName'
        onkeypress="return String.fromCharCode(event.charCode).match(/[\.\$\[\]\#\/]/g) === null">
    </mat-form-field>
  </section>


  <section>
    <h4>Contact Person</h4>

    <div class="row">
      <div class="col">

        <mat-form-field>
          <mat-label>First Name Last Name</mat-label>
          <input matInput formControlName='contactName'>
        </mat-form-field>

      </div>
      <div class="col">

        <mat-form-field>
          <mat-label>Contact ID</mat-label>
          <input matInput formControlName='contactId'>
        </mat-form-field>
      </div>
    </div>
  </section>


</div>

<div mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button (click)="onDiscard()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!labForm.valid" (click)="save()" cdkFocusInitial>Save</button>
</div>
