import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Organization } from '@vizodev/syntezza-sales-types/lib';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExportService } from 'src/app/services/export/export.service';
import { OrganizationsService } from 'src/app/services/organizations/organizations.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
  accountsForm = new FormGroup({
    prepay: new FormControl('All'),
    organization: new FormControl('All', []),
    minLastOrderDate: new FormControl(null),
    maxLastOrderDate: new FormControl(null),
    maxBalance: new FormControl(null),
  });

  ordersForm = new FormGroup({
    minLastOrderDate: new FormControl(null),
    maxLastOrderDate: new FormControl(null),
    origin: new FormControl('All'),
  });

  exportingAccounts = false;
  exportingOrders = false;
  exportingContacts = false;

  organizationControl = new FormControl(null);
  organizations: Organization[] = [];
  orgFilter = new FormControl();

  filteredOrganizations = new ReplaySubject<Organization[]>(1);
  protected _onDestroy = new Subject<void>();
  subscriptions = new Subscription();

  constructor(
    private exportService: ExportService,
    public organizationsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.organizationControl.valueChanges.subscribe((org) => {
        this.accountsForm.setControl('organization', new FormControl(!org ? null : org.organizationId));
      })
    );

    this.subscriptions.add(
      this.organizationsService.organizations$.subscribe((orgs) => {
        this.organizations = orgs;
        this.filteredOrganizations.next(orgs.slice());
      })
    );

    this.orgFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOrgs();
      });
  }

  protected filterOrgs() {
    if (!this.organizations) {
      return;
    }
    // get the search keyword
    let search = this.orgFilter.value;
    if (!search) {
      this.filteredOrganizations.next(this.organizations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredOrganizations.next(
      this.organizations.filter(
        (o) => o.name.toLowerCase().startsWith(search)
      )
    );
  }

  async exportAccounts() {
    if (!this.accountsForm.valid) return;
    this.exportingAccounts = true;
    await this.exportService.exportAccounts(this.accountsForm.value);
    this.exportingAccounts = false;
  }

  async exportOrders() {
    if (!this.ordersForm.valid) return;
    this.exportingOrders = true;
    await this.exportService.exportOrders(this.ordersForm.value);
    this.exportingOrders = false;
  }

  async exportContacts() {
    this.exportingContacts = true;
    await this.exportService.exportContacts();
    this.exportingContacts = false;
  }
}
