import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<any>;

  constructor(private auth: AngularFireAuth) {
    this.user = this.auth.user;
  }

  public get authState() {
    return this.auth.authState;
  }

  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  forgotPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  getToken() {
    return this.auth.idToken;
  }

  logout() {
    return this.auth.signOut();
  }
}
