import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements OnInit {
  date: any;

  constructor(public dialogRef: MatDialogRef<SelectDateComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close('CANCEL');
  }

  save() {
    //console.log(this.date);
    this.dialogRef.close(this.date.toDate());
  }
}
