import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
} from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { of } from 'rxjs';
import {
  Organization,
  Laboratory,
  ContactPerson,
  Order,
  PrePay,
  Balance,
} from '@vizodev/syntezza-sales-types/lib';
import { catchError, map, take, tap } from 'rxjs/operators';
import { type } from 'os';
@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  limit = 10;

  orgCollection: AngularFirestoreCollection;
  labCollection: AngularFirestoreCollection;

  constructor(
    private db: AngularFirestore,
    private realtimedb: AngularFireDatabase
  ) {
    this.orgCollection = db.collection('organizations', (ref) =>
      ref.orderBy('name')
    );
    this.labCollection = db.collection('laboratories');
  }

  public get createId(): string {
    return this.db.createId();
  }

  getOrganizations() {
    return this.orgCollection.snapshotChanges().pipe(
      map((snap) => snap.map((o) => Organization.fromFirestore(o.payload.doc))),
      catchError((e) => {
        console.error(e);
        return of([]);
      })
    );
  }

  searchAllOrders(query: string, limit: number, lastSnap?: any) {
    return this.db
      .collectionGroup('orders', (ref) => {
        let q = ref.limit(limit).where('orderNumber', '==', query);

        if (lastSnap) {
          q = q.startAfter(lastSnap);
        }

        return q;
      })
      .snapshotChanges()
      .pipe(
        take(1),
        catchError((e) => {
          console.error(e);
          return of(null);
        })
      );
  }

  getLaboratories(
    limit: number,
    query: number,
    organization: Organization,
    pi: string,
    prepay: any = null,
    orderBy: string = 'bpCode',
    direction: any = 'asc',
    lastSnap?: any
  ) {
    console.log(
      'getLaboratories',
      `limit: ${limit}`,
      `query: ${query}`,
      `organization: ${organization}`,
      `pi: ${pi}`,
      `prepay: ${prepay}`,
      `orderBy: ${orderBy}`,
      `lastSnap`,
      lastSnap?.data().bpCode
    );
    return this.db
      .collection('laboratories', (ref) => {
        let q = ref.limit(limit);

        if (query && query.toString().length) {
          if (typeof query === 'number') {
            q = q.orderBy('bpCode', direction).where('bpCode', '>=', query);
          }
        }

        if (
          orderBy &&
          orderBy.length &&
          !(orderBy === 'bpCode' && typeof query === 'number') &&
          !(orderBy === 'pi.name' && pi)
        ) {
          q = q.orderBy(orderBy, direction);
        }

        if (organization) {
          q = q.where('organization.organizationId', '==', organization);
        }

        if (pi) {
          q = q.where('pi.name', '==', pi);
        }

        if (`${prepay}` != 'null') {
          q = q.where('hasPrepay', '==', prepay === 'true' || prepay === true);
        }

        if (lastSnap) {
          q = q.startAfter(lastSnap);
        }

        return q;
      })
      .snapshotChanges()
      .pipe(take(1));
  }

  getLabById(labId: string): Promise<Laboratory | null> {
    return this.labCollection
      .doc(labId)
      .get()
      .pipe(
        map((snap) => Laboratory.fromFirestore(snap)),
        catchError((e) => of(null))
      )
      .toPromise();
  }

  getOrdersFromLaboratory(
    laboratoryId: string,
    limit: number,
    minDate: Date,
    startAfter?: any
  ) {
    console.log(laboratoryId, limit, minDate, startAfter?.id);

    return this.labCollection
      .doc(laboratoryId)
      .collection('orders', (ref) => {
        let q = ref.orderBy('date', 'asc').limit(limit);
        //if (minDate) q = q.where('date', '>=', minDate);
        if (startAfter) q = q.startAfter(startAfter);
        return q;
      })
      .snapshotChanges()
      .pipe(
        tap((data) =>
          console.log(data.map((d) => d.payload.doc.data()), data.length)
        )
      );
  }

  getOrderById(labId: string, orderId: string) {
    return this.labCollection
      .doc(labId)
      .collection('orders')
      .doc(orderId)
      .get()
      .pipe(map((snap) => (snap ? Order.fromFirestore(snap) : null)))
      .toPromise();
  }

  getPrepaysFromLaboratory(
    laboratoryId: string,
    limit: number,
    minDate: Date,
    startAfter?: any
  ) {
    return this.labCollection
      .doc(laboratoryId)
      .collection('prepays', (ref) => {
        let q = ref.orderBy('date', 'desc').limit(limit);
        //if (minDate) q = q.where('date', '>=', minDate);
        if (startAfter) q = q.startAfter(startAfter);
        return q;
      })
      .snapshotChanges();
  }

  getContactsFromLaboratory(laboratoryId: string) {
    return this.labCollection
      .doc(laboratoryId)
      .collection('contactPersons')
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((o) => ContactPerson.fromFirestore(o.payload.doc))
        )
      );
  }

  createOrganization(organization: Organization) {
    organization.organizationId = this.db.createId();
    return this.orgCollection
      .doc(organization.organizationId)
      .set(organization.toMap());
  }

  updateOrganization(organization: Organization) {
    return this.orgCollection
      .doc(organization.organizationId)
      .update(organization.toMap());
  }

  deleteOrganization(organizationId: string) {
    return this.orgCollection.doc(organizationId).delete();
  }

  createLaboratory(lab: Laboratory) {
    lab.laboratoryId = this.db.createId();
    return this.labCollection.doc(lab.laboratoryId).set(lab.toMap());
  }

  saveLaboratory(lab: Laboratory) {
    return this.labCollection.doc(lab.laboratoryId).set(lab.toMap());
  }

  updateLaboratory(lab: Laboratory) {
    return this.labCollection.doc(lab.laboratoryId).update(lab.toMap());
  }

  updateLaboratoryBalance(labId: string, balance: number) {
    return this.labCollection.doc(labId).update({ balance });
  }

  deleteLaboratory(labId: string) {
    return this.labCollection.doc(labId).delete();
  }

  updatedOrder(order: Order) {
    return this.labCollection
      .doc(order.laboratoryInfo.laboratoryId)
      .collection('orders')
      .doc(order.orderId)
      .update(order.toMap());
  }

  deleteOrder(order: Order) {
    return this.labCollection
      .doc(order.laboratoryInfo.laboratoryId)
      .collection('orders')
      .doc(order.orderId)
      .delete();
  }

  createOrder(labId: string, order: Order) {
    order.orderId = this.db.createId();
    return this.labCollection
      .doc(labId)
      .collection('orders')
      .doc(order.orderId)
      .set(order.toMap());
  }

  createContactPerson(contact: ContactPerson) {
    contact.contactPersonId = this.db.createId();
    return this.labCollection
      .doc(contact.laboratoryInfo.laboratoryId)
      .collection('contactPersons')
      .doc(contact.contactPersonId)
      .set(contact.toMap());
  }

  saveContactPerson(contact: ContactPerson) {
    return this.labCollection
      .doc(contact.laboratoryInfo.laboratoryId)
      .collection('contactPersons')
      .doc(contact.contactPersonId)
      .set(contact.toMap());
  }

  updateContactPerson(contact: ContactPerson) {
    return this.labCollection
      .doc(contact.laboratoryInfo.laboratoryId)
      .collection('contactPersons')
      .doc(contact.contactPersonId)
      .update(contact.toMap());
  }

  deleteContactPerson(contact: ContactPerson) {
    return this.labCollection
      .doc(contact.laboratoryInfo.laboratoryId)
      .collection('contactPersons')
      .doc(contact.contactPersonId)
      .delete();
  }

  createPrePay(prepay: PrePay) {
    prepay.prepayId = this.db.createId();
    return this.labCollection
      .doc(prepay.laboratoryInfo.laboratoryId)
      .collection('prepays')
      .doc(prepay.prepayId)
      .set(prepay.toMap());
  }

  updatePrePay(prepay: PrePay) {
    return this.labCollection
      .doc(prepay.laboratoryInfo.laboratoryId)
      .collection('prepays')
      .doc(prepay.prepayId)
      .update(prepay.toMap());
  }

  deletePrePay(prepay: PrePay) {
    return this.labCollection
      .doc(prepay.laboratoryInfo.laboratoryId)
      .collection('prepays')
      .doc(prepay.prepayId)
      .delete();
  }

  searchContactPersonByContactId(contactId: string) {
    return this.db
      .collectionGroup('contactPersons', (ref) =>
        ref.where('contactId', '==', contactId)
      )
      .get()
      .pipe(
        map((snap) =>
          snap.empty ? null : ContactPerson.fromFirestore(snap.docs[0])
        )
      )
      .toPromise();
  }

  async saveOrdersToLaboratory(
    labId: string,
    orders: Order[],
    updateIfExists: boolean = false
  ) {
    const existingOrders = [];

    for await (const order of orders) {
      const snap = await this.labCollection
        .doc(labId)
        .collection('orders', (ref) =>
          ref.where('orderNumber', '==', order.orderNumber)
        )
        .get()
        .toPromise();
      if (!snap.empty) {
        if (updateIfExists) {
          await this.labCollection
            .doc(`${labId}/orders/${snap.docs[0].id}`)
            .update(order.toMap());
        }
        existingOrders.push(order.orderNumber);
      }
    }

    const batch = this.db.firestore.batch();

    for (const order of orders.filter(
      (o) => existingOrders.indexOf(o.orderNumber) === -1
    )) {
      batch.set(
        this.labCollection.doc(labId).collection('orders').doc(order.orderId)
          .ref,
        order.toMap()
      );
    }

    await batch.commit();

    return existingOrders;
  }

  async getBalanceForDate(labId: string, date: Date) {
    const snap = await this.labCollection
      .doc(labId)
      .collection('balance', (ref) =>
        ref.where('date', '<=', date).orderBy('date', 'desc').limit(1)
      )
      .get()
      .toPromise();

    return snap.empty ? null : Balance.fromMap(snap.docs[0]);
  }

  async getFilters() {
    return (await this.realtimedb.database.ref('index').once('value')).toJSON();
  }

  async getInitialBalance(labId: string) {
    const snap = await this.labCollection
      .doc(labId)
      .collection('balance')
      .doc('initial')
      .get()
      .toPromise();
    return snap.exists ? Balance.fromMap(snap) : null;
  }

  async getBalancesAfterDate(labId: string, date: Date) {
    const snap = await this.labCollection
      .doc(labId)
      .collection('balance', (ref) => ref.where('date', '>', date))
      .get()
      .toPromise();
    return snap.docs.map((d) => Balance.fromMap(d));
  }

  saveBalance(balance: Balance) {
    return this.labCollection
      .doc(balance.labInfo.laboratoryId)
      .collection('balance')
      .doc(balance.balanceId)
      .set(balance.toMap());
  }

  deleteBalance(balance: Balance) {
    return this.labCollection
      .doc(balance.labInfo.laboratoryId)
      .collection('balance')
      .doc(balance.balanceId)
      .delete();
  }

  updateBalanceCurrentValue(
    labId: string,
    balanceId: string,
    currentBalance: number
  ) {
    return this.labCollection
      .doc(labId)
      .collection('balance')
      .doc(balanceId)
      .update({ currentBalance });
  }
}
