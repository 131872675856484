import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { UploadComponent } from './pages/upload/upload.component';
import { HeaderComponent } from './components/header/header.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { DropzoneDirective } from 'src/app/directives/dropzone.directive';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HomeComponent } from './pages/home/home.component';
import { LabsListComponent } from './components/labs-list/labs-list.component';
import { LabComponent } from './pages/lab-details-pages/lab/lab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LabDetailsComponent } from './pages/lab-details-pages/lab-details/lab-details.component';
import { LabPrepaysComponent } from './pages/lab-details-pages/lab-prepays/lab-prepays.component';
import { LabOrdersComponent } from './pages/lab-details-pages/lab-orders/lab-orders.component';
import { AddPrepayDialogComponent } from './components/dialogs/add-prepay-dialog/add-prepay-dialog.component';
import { AddContactDialogComponent } from './components/dialogs/add-contact-dialog/add-contact-dialog.component';
import { AddLabDialogComponent } from './components/dialogs/add-lab-dialog/add-lab-dialog.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ExportComponent } from './pages/export/export.component';
import { AddOrderDialogComponent } from './components/dialogs/add-order-dialog/add-order-dialog.component';
import { AddOrganizationComponent } from './components/dialogs/add-organization/add-organization.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectOriginComponent } from './components/dialogs/select-origin/select-origin.component';
import { SearchComponent } from './pages/search/search.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ErrorReportComponent } from './components/dialogs/error-report/error-report.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { SelectDateComponent } from './components/dialogs/select-date/select-date.component';

@NgModule({
  declarations: [
    DropzoneDirective,
    IndexComponent,
    UploadComponent,
    HeaderComponent,
    PdfViewerComponent,
    UploadTaskComponent,
    HomeComponent,
    LabsListComponent,
    LabComponent,
    LabDetailsComponent,
    LabOrdersComponent,
    LabPrepaysComponent,
    AddPrepayDialogComponent,
    AddContactDialogComponent,
    AddLabDialogComponent,
    ExportComponent,
    AddOrderDialogComponent,
    AddOrganizationComponent,
    SelectOriginComponent,
    SearchComponent,
    ErrorReportComponent,
    SelectDateComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    PdfViewerModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatButtonToggleModule,
    ClipboardModule
  ],
  entryComponents: [
    AddPrepayDialogComponent,
    AddContactDialogComponent,
    AddLabDialogComponent,
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ]
})
export class DashboardModule {}
