import { Component, Input, OnInit } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { OrderOrigin } from '@vizodev/syntezza-sales-types/lib';
import { error } from 'protractor';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { first, last, multicast } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UploadService } from 'src/app/services/upload/upload.service';
import { ErrorReportComponent } from '../dialogs/error-report/error-report.component';
import { SelectOriginComponent } from '../dialogs/select-origin/select-origin.component';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;

  percentage: Observable<number>;
  task: Observable<{ status: string; errors: any[]; successes: number }>;

  sub = new Subscription();
  constructor(
    private uploadService: UploadService,
    private alert: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.startUpload();
  }

  async startUpload() {
    const { origin, updateIfExists } = await this.getOrigin(this.file.name);
    //console.log(origin, updateIfExists);

    if (!origin) return;

    const { percentage, task } = this.uploadService.uploadCSV(
      this.file,
      origin,
      updateIfExists
    );
    this.percentage = percentage;
    this.task = task;

    this.task.subscribe((task) => {
      if (task.status === 'COMPLETED') {
        if (task.errors.length > 0) {
          this.showErrors(task.errors);
        } else {
          this.alert.success(
            `${this.file.name} was imported successfully - Total Order: ${task.successes}`
          );
        }
      } else if (task.status === 'ERROR') {
        this.showErrors(task.errors);
      }
    });
  }

  showErrors(errors: string[]) {
    const dialog = this.dialog.open(ErrorReportComponent, {
      width: '440px',
      data: errors,
    });
  }

  getOrigin(
    filename: string
  ): Promise<{ origin: OrderOrigin; updateIfExists: boolean }> {
    return new Promise((res, rej) => {
      const dialog = this.dialog.open(SelectOriginComponent, {
        width: '440px',
        data: {
          filename,
        },
      });

      this.sub.add(
        dialog.afterClosed().subscribe((data) => {
          res(data);
        })
      );
    });
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
