import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { Laboratory } from '@vizodev/syntezza-sales-types/lib';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterService } from 'src/app/core/router/router.service';
import { LabsService } from 'src/app/services/labs/labs.service';

import { AddContactDialogComponent } from '../../../components/dialogs/add-contact-dialog/add-contact-dialog.component';
import { AddOrderDialogComponent } from '../../../components/dialogs/add-order-dialog/add-order-dialog.component';
import { AddPrepayDialogComponent } from '../../../components/dialogs/add-prepay-dialog/add-prepay-dialog.component';
import { SelectDateComponent } from '../../../components/dialogs/select-date/select-date.component';

@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit, OnDestroy {
  labNavLinks: any[];
  activeLinkIndex = 0;

  lab: Laboratory;

  sub = new Subscription();

  constructor(
    private router: RouterService,
    private actRoute: ActivatedRoute,
    public dialog: MatDialog,
    public labsService: LabsService
  ) {
    this.labNavLinks = [
      {
        label: 'Details',
        link: 'details',
        index: 0,
      },
      {
        label: 'Orders',
        link: 'orders',
        index: 1,
      },
      {
        label: 'PrePays',
        link: 'prepays',
        index: 2,
      },
    ];
  }

  ngOnInit(): void {
    this.getLab();
    this.parseUrl(this.router.currentRoute);
    this.sub.add(
      this.router.onChangeRoute.subscribe((data: any) => {
        if (data.url) {
          this.parseUrl(data.url);
        }
      })
    );
  }

  parseUrl(url: string) {
    //console.log(url);

    if (url.indexOf('orders') !== -1) {
      this.activeLinkIndex = 1;
    } else if (url.indexOf('prepays') !== -1) {
      this.activeLinkIndex = 2;
    } else {
      this.activeLinkIndex = 0;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async getLab() {
    const labId = this.actRoute.snapshot.params.labId;
    if (this.labsService.currentLab?.laboratoryId === labId)
      this.lab = this.labsService.currentLab;
    else {
      await this.labsService.getLab(labId);
      this.lab = this.labsService.currentLab;
    }
  }

  async exportReport() {
    const date = await this.getMinimumDate();
    if (date === 'CANCEL') return;
    this.labsService.exportReport(
      this.lab.laboratoryId,
      date as Date | null,
      this.lab.pi.name
    );
  }

  getMinimumDate(): Promise<Date | null | string> {
    const dialog = this.dialog.open(SelectDateComponent, {
      width: '440px',
    });

    return new Promise((res) => {
      this.sub.add(
        dialog.afterClosed().subscribe((data) => {
          res(data ? data : null);
        })
      );
    });
  }

  openOrderDialog() {
    //console.log(this.labsService.currentInitialBalance);

    const dialogRef = this.dialog.open(AddOrderDialogComponent, {
      width: '440px',
      data: {
        minDate: this.labsService.currentInitialBalance.date,
      },
    });

    this.sub.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const {
            orderNumber,
            webNumber,
            contactPerson,
            date,
            amount,
            origin,
          } = result;
          this.labsService.addOrder(
            this.lab,
            orderNumber,
            webNumber,
            contactPerson,
            date,
            amount,
            origin
          );
        }
      })
    );
  }

  openPrepayDialog() {
    //console.log('Open Prepay', this.labsService.currentInitialBalance);

    const dialogRef = this.dialog.open(AddPrepayDialogComponent, {
      width: '440px',
      data: {
        minDate: this.labsService.currentInitialBalance?.date ?? null,
      },
    });

    this.sub.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const { sapInvoice, poNumber, amount, extraAmount, date } = result;
          this.labsService.addPrepay(
            this.lab,
            sapInvoice,
            poNumber,
            amount,
            extraAmount,
            date
          );
        }
      })
    );
  }

  openContactDialog() {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      width: '440px',
    });

    this.sub.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const { contactId, name } = result;
          this.labsService.addContactPerson(this.lab, contactId, name);
        }
      })
    );
  }

  async recalculateBalance() {
    if (!confirm('This is a heavy operation. Are you sure you want to continue? It will take a couple of minutes to complete.')) return;

    await this.labsService.recalculateBalance();
  }
}
