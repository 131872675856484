import {
  IOrdersImport,
  Order,
  OrderOrigin,
} from '@vizodev/syntezza-sales-types/lib';

import { BehaviorSubject } from 'rxjs';

import { DatabaseService } from 'src/app/core/database/database.service';

export class CsvImporter {
  percentage = new BehaviorSubject(0);
  taskSubject = new BehaviorSubject({
    status: 'STOPPED',
    successes: 0,
    errors: [],
  });

  months = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
  };

  headers = [
    {
      name: 'orderNumber',
      parser: (e: string) => e,
    },
    {
      name: 'webNumber',
      parser: (e: string) => e ?? null,
    },
    {
      name: 'date',
      parser: (e: string) => {
        if (!e.length) return null;

        if (!e.match(/([0-9]{1,2})-([A-z]{3,})-([0-9]{2})/g)) {
          throw new Error(
            `Invalid date "${e}". The supported format is: 9-Jan-20`
          );
        }

        var p = e.split('-');
        return new Date(
          parseInt(`${p[2].length === 2 ? '20' + p[2] : p[2]}`),
          this.months[p[1].toLowerCase()],
          parseInt(p[0])
        );
      },
    },
    {
      name: 'contactId',
      parser: (e: string) => e,
    },
    {
      name: 'amount',
      parser: (e: string) => {
        const num = Math.abs(parseFloat(e.replace(',', '')));
        if (isNaN(num)) {
          throw new Error(`Invalid amount "${e}"`);
        }
        return num;
      },
    },
  ];

  errors = [];
  successes = 0;

  origin: OrderOrigin;

  minDate = new Date(new Date().getTime() - 5.184e9);

  constructor(private file: File, private database: DatabaseService) {}

  public get percentage$() {
    return this.percentage.asObservable();
  }

  public get task$() {
    return this.taskSubject.asObservable();
  }

  public set task(t: { v: string; s?: number }) {
    this.taskSubject.next({
      status: t.v,
      successes: t.s ?? 0,
      errors: this.errors,
    });
  }

  upload(origin: OrderOrigin, updateIfExists = false) {
    this.origin = origin;
    this.task = { v: 'UPLOADING' };
    const reader = new FileReader();
    reader.readAsText(this.file);

    reader.onload = () => {
      const csvData = reader.result;
      return this.parse(csvData as string, updateIfExists);
    };
  }

  async parse(data: string, updateIfExists = false) {
    //console.log('Data', data);
    const lines = data
      .replace(/\"/g, '')
      .split('\r\n')
      .filter((l) => !!l && l.length > 0);
    //console.log('Lines', lines);
    const headers = lines
      .shift()
      .split(/,/g)
      .filter((o) => !!o);

    if (headers.length !== this.headers.length) {
      this.errors = [
        `Expected ${this.headers.length} headers, but got ${headers.length}`,
      ];
      this.task = { v: 'ERROR' };
      return;
    }

    try {
      const objects = this.convertToObjectArray(headers, lines);

      this.percentage.next(25);

      //console.log(objects);

      const ordersImport = this.createOrdersImport(objects);

      this.percentage.next(50);

      //console.log(ordersImport);

      await this.saveOrders(ordersImport, updateIfExists);

      this.percentage.next(100);

      this.task = { v: 'COMPLETED', s: this.successes };
    } catch (error) {
      this.errors = [error];
      this.task = { v: 'ERROR' };
    }
  }

  private convertToObjectArray(headers: any, lines: any[]) {
    const objects = [];

    for (let j = 0; j < lines.length; j++) {
      try {
        const line = lines[j];
        const fields = (line.split(',') as string[]).filter((e, i) => {
          return i < this.headers.length || !!e;
        });
        //console.log('Fields', fields);

        if (fields.join('').length === 0) {
          break;
        }
        if (fields.length !== this.headers.length) {
          throw new Error(
            `Expected ${this.headers.length} parameters, but got ${fields.length}. Please make sure the document is formatted as text, not number or currency.`
          );
        }
        const obj = {};
        for (let i = 0; i < headers.length; i++) {
          const h = this.headers[i];
          obj[h.name] = h.parser(fields[i]);
        }

        if (this.origin == OrderOrigin.MACROGEN && !obj['date']) {
          obj['date'] = this.extractDateFromOrderNumber(obj['orderNumber']);
        }

        if (!obj['date'] || isNaN(obj['date'].getTime())) {
          throw new Error('Invalid date');
        }

        if (obj['date'].getTime() < this.minDate.getTime()) {
          if (
            !confirm(
              `Are you sure you want to upload an order from ${obj['date']}?`
            )
          ) {
            throw new Error('60 days limit exceeded');
          }
        }

        obj['origin'] = this.origin;
        objects.push(obj);
      } catch (error) {
        throw new Error(`Line ${j + 1}: ${error}`);
      }
    }

    return objects;
  }

  private extractDateFromOrderNumber(orderNumber: string) {
    if (!orderNumber) return null;
    const year = orderNumber.substr(0, 2);
    const month = orderNumber.substr(2, 2);
    const day = orderNumber.substr(4, 2);
    const date = new Date(`${month}/${day}/${year}`);
    return date;
  }

  private createOrdersImport(data: any) {
    const orders: IOrdersImport = {
      data: [],
    };

    !data
      ? null
      : data.forEach((order) => {
          const i = orders.data.findIndex(
            (o) => o.contactId === order['contactId']
          );
          if (i != -1) {
            orders.data[i].orders.push(order);
          } else {
            orders.data.push({
              contactId: order['contactId'],
              orders: [order],
            });
          }
        });

    return orders;
  }

  private async saveOrders(imports: IOrdersImport, updateIfExists = false) {
    const factor = 45 / (imports.data.length - 1);
    for await (const order of imports.data) {
      try {
        order.orders.sort((a, b) =>
          new Date(a.date) > new Date(b.date) ? 1 : -1
        );
        await this.saveOrder(order, updateIfExists);
      } catch (error) {
        //console.error(error);
        this.errors.push(error);
      }
      this.percentage.next(this.percentage.value + factor);
    }
  }

  private async saveOrder(
    order: {
      contactId: string;
      orders: {
        orderNumber: string;
        date: Date;
        amount: number;
        origin: OrderOrigin;
        webNumber: string;
      }[];
    },
    updateIfExists = false
  ) {
    const contactPerson = await this.database.searchContactPersonByContactId(
      order.contactId
    );

    if (!contactPerson) {
      throw new Error('Contact Person not found: ' + order.contactId);
    }

    const labId = contactPerson.laboratoryInfo.laboratoryId;

    const lab = await this.database.getLabById(labId);

    if (!lab) {
      throw new Error(
        'Lab not found: ' +
          contactPerson.laboratoryInfo.bpCode +
          ' for Contact ID: ' +
          contactPerson.contactId
      );
    }

    let balance = lab.balance;

    const orders = [];

    for (const o of order.orders) {
      orders.push(
        new Order(
          this.database.createId,
          o.date,
          o.orderNumber,
          o.webNumber,
          contactPerson,
          o.origin,
          o.amount,
          balance
        )
      );
      balance = balance - o.amount;
    }

    let existingOrders = [];
    try {
      existingOrders = await this.database.saveOrdersToLaboratory(
        labId,
        orders,
        updateIfExists
      );
    } catch (error) {
      //console.log(error);
      throw new Error('Failed saving orders: ' + error);
    }

    if (existingOrders.length && !updateIfExists) {
      throw new Error(
        'Some order were found in the system and were not imported\n' +
          existingOrders.join(', ')
      );
    }
    this.successes +=
      orders.length - (updateIfExists ? 0 : existingOrders.length);
  }
}
