import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss']
})
export class ErrorReportComponent implements OnInit {

  formattedText: string;

  message;

  constructor(
    public dialogRef: MatDialogRef<ErrorReportComponent>,
    @Inject(MAT_DIALOG_DATA) public errors: string[],
    private clipboard: Clipboard,
  ) {

    this.formattedText = errors.join('\n\n');

  }

  ngOnInit(): void {
  }

  copy() {
    this.message = null;
    if (this.clipboard.copy(this.formattedText)) {
      this.message = 'Copied!';
    } else {
      this.message = 'Oops... Could not copy...';
    }
  }
}
